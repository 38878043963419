import { Link, Text } from '@luminovo/design-system';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { NegotiationPart } from '../hooks/queries';
import { formatNegotiationPart } from '../model/formatNegotiationPart';

export function NegotiationPartLabel({ part }: { part?: NegotiationPart }): JSX.Element {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnPartDrawer } = useIpnDetailsDrawer();

    // TODO(negotiations): add support for other part types
    if (!part) {
        return <Text>-</Text>;
    }

    return (
        <Link
            attention="low"
            onClick={() => {
                if (part.kind === 'off_the_shelf') {
                    openOtsPartDrawer({ part: { id: part.id }, rfqContext: { type: 'OutsideRfQ' } });
                } else if (part.kind === 'component') {
                    openIpnPartDrawer({ ipnId: part.ipn_id, rfqContext: { type: 'OutsideRfQ' } });
                }
            }}
        >
            {formatNegotiationPart(part)}
        </Link>
    );
}
