import { t } from '@lingui/macro';
import { assertPresent, formatToLongDate, isPresent, transEnum } from '@luminovo/commons';
import { PriceType, SolutionPreferenceDTO, SourcingPreferenceOptions } from '@luminovo/http-client';
import { packagingTranslations } from '../i18n';

export function visibleSolutionPreferences(solutionPreference: SolutionPreferenceDTO): string[] {
    /* eslint-disable camelcase */
    const {
        lead_time_preference,
        packaging_preference,
        inventory_preference,
        prefer_direct_api_integrations,
        excluded_price_types,
        prioritize_preferred_manufacturers,
        prefer_customer_negotiated_prices,
    } = solutionPreference;

    return [
        lead_time_preference.type === SourcingPreferenceOptions.Fastest ? t`Fastest` : undefined,
        lead_time_preference.type === SourcingPreferenceOptions.BestPrice ? t`Best price` : undefined,
        lead_time_preference.type === SourcingPreferenceOptions.BestPriceBy
            ? t`Best price by ${formatToLongDate(assertPresent(lead_time_preference.target))}`
            : undefined,
        packaging_preference.length > 0
            ? t`Prefer ${packaging_preference.map((p) => transEnum(p, packagingTranslations)).join(', ')}`
            : undefined,
        inventory_preference === 'PreferInventory' ? t`Prefer inventory` : undefined,
        prefer_direct_api_integrations ? t`Prefer direct API integrations` : undefined,
        prioritize_preferred_manufacturers ? t`Prioritize preferred manufacturers` : undefined,
        excluded_price_types.includes(PriceType.ListPrice) ? t`Exclude list prices` : undefined,
        excluded_price_types.includes(PriceType.ContractPrice) ? t`Exclude contract prices` : undefined,
        excluded_price_types.includes(PriceType.QuotePrice) ? t`Exclude quote prices` : undefined,
        excluded_price_types.includes(PriceType.PurchasePrice) ? t`Exclude purchase prices` : undefined,
        excluded_price_types.includes(PriceType.StandardPrice) ? t`Exclude standard prices` : undefined,
        excluded_price_types.includes(PriceType.CustomerNegotiatedPrice)
            ? t`Exclude customer negotiated prices`
            : undefined,
        prefer_customer_negotiated_prices ? t`Prioritize customer negotiated prices` : undefined,
    ].filter(isPresent);
    /* eslint-enable camelcase */
}
