import { QuoteRequest } from '../hooks/queries';

export type QuoteRequestStatus = 'not-sent' | 'sent' | 'overdue' | 'discarded' | 'received';

export function getQuoteRequestStatus(quoteRequest: QuoteRequest): QuoteRequestStatus {
    if (quoteRequest.discarded) {
        return 'discarded';
    }
    if (quoteRequest.received_date) {
        return 'received';
    }

    if (quoteRequest.due_date) {
        const dueDate = new Date(quoteRequest.due_date);
        const now = new Date();
        if (dueDate < now) {
            return 'overdue';
        }
    }

    if (quoteRequest.sent_date) {
        return 'sent';
    }
    return 'not-sent';
}
