/* eslint-disable spellcheck/spell-checker */
import { plural, t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    CenteredLayout,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Flexbox,
    PrimaryButton,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import {
    Bom422ErrorResponseRuntype,
    BomLineBuildingOutput,
    BomScreeningSheet,
    ColumnMap,
    ColumnName,
    PartSuggestionReasonEnum,
} from '@luminovo/http-client';
import { WarningRounded } from '@mui/icons-material/';
import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router';
import InformationDialogBox from '../../components/dialogBox/InformationDialogBox';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { postImportBom } from '../../resources/bomImporter/bomImporterHandler';
import { Level } from '../../resources/bomImporter/bomImporterIssuesEnum';
import { httpQueryKey } from '../../resources/http/httpQueryKey';
import { invalidateAllQueriesForEndpoint } from '../../resources/http/useHttpQuery';
import { colorSystem } from '../../themes';
import { assertAgainstRuntype } from '../../utils/customConsole';
import { route } from '../../utils/routes';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { BomIssueItem } from './components/bomImporterDialogComponents';
import { DialogAppBar } from './components/DialogAppBar';
import { DialogTitle as DialogAppBarTitle } from './components/DialogTitle';
import { Transition } from './StyledDialog';

export function BomImporterLinesDialog({
    open,
    bomImporterLines,
    bomScreeningSheet,
    assemblyId,
    bomImporterFile,
    onClose,
    onBack,
    errorAndResetBomStateCallback,
    rfqId,
    columnMap,
    viewContext,
}: {
    bomImporterLines: BomLineBuildingOutput;
    bomScreeningSheet: BomScreeningSheet;
    open: boolean;
    assemblyId: string;
    bomImporterFile: File;
    onClose: () => void;
    onBack: () => void;
    rfqId: string;
    errorAndResetBomStateCallback: (message: string) => void;
    columnMap: ColumnMap;
    viewContext: ViewContext;
}) {
    const queryClient = useQueryClient();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();
    const token = getToken();

    const hasAtLeastOneErrorInBom =
        false &&
        bomImporterLines.lines.items.flatMap((line) => line.issues).some((issue) => issue.level === Level.Error);

    const countBomItemsWithChangesFromPrevBomImport = bomImporterLines.lines.items.filter((item) =>
        item.bom_item?.part_suggestions.some((suggestion) => suggestion.origin?.column === ColumnName.Previous),
    ).length;

    const countSuggestionsFromPrevBomImport = bomImporterLines.lines.items.reduce(
        (acc, item) =>
            // We don't want to consider removed part options here as they would not be automatically added
            acc +
            (item.bom_item?.part_suggestions.filter(
                (suggestion) =>
                    suggestion.origin?.column === ColumnName.Previous &&
                    !suggestion.origin?.reasons.some(
                        (reason) => reason.name === PartSuggestionReasonEnum.PreviousImportRemovedPartOption,
                    ),
            ).length || 0),
        0,
    );
    const hasSuggestionsFromPrevBomImport = countBomItemsWithChangesFromPrevBomImport > 0;

    const [isErrorsInBomWarningModalOpen, setIsErrorsInBomWarningModalOpen] =
        React.useState<boolean>(hasAtLeastOneErrorInBom);

    React.useEffect(() => {
        if (hasAtLeastOneErrorInBom) {
            setIsErrorsInBomWarningModalOpen(true);
        }
    }, [hasAtLeastOneErrorInBom]);

    const allLineIssues = bomImporterLines.lines.items.flatMap((line) => line.issues);

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: ({ acceptPrevBomLines }: { acceptPrevBomLines: boolean }) =>
            postImportBom(
                {
                    bomImporterLines,
                    headerMetadata: bomScreeningSheet.header_metadata,
                    assemblyId,
                    columnMap,
                },
                bomImporterFile,
                enqueueSnackbar,
                token,
                acceptPrevBomLines,
            ),
        onSuccess: async () => {
            await Promise.allSettled([
                queryClient.invalidateQueries(httpQueryKey('POST /parts/off-the-shelf/bulk')),
                queryClient.invalidateQueries(httpQueryKey('POST /design-items/bulk')),
                queryClient.invalidateQueries(httpQueryKey('POST /parts/generic/bulk')),
                queryClient.invalidateQueries(httpQueryKey('POST /ipns/bulk')),
                invalidateAllQueriesForEndpoint('GET /assemblies/:assemblyId/descendants', queryClient),
                invalidateAllQueriesForEndpoint('GET /assemblies/:assemblyId/descendants-summary', queryClient),
            ]);
            const importEventState: BomImportEventState = { imported: true, columnMap: columnMap };
            const detailsPage =
                viewContext.type === 'WithinRfQ'
                    ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/details', { assemblyId, rfqId })
                    : route('/assemblies/:assemblyId/details', { assemblyId });
            history.push({
                pathname: detailsPage,
                state: importEventState,
            });
        },
        onError: (error: AxiosResponse<unknown>) => {
            let errorMessage = t`Could not upload BOM.`;
            if (error.status === 422) {
                assertAgainstRuntype(error.data, Bom422ErrorResponseRuntype);
                if (Bom422ErrorResponseRuntype.guard(error.data)) {
                    if (error.data.code === 'bom_import.duplicate_designators') {
                        errorMessage +=
                            ' ' +
                            t`The BOM you are trying to import has a conflicting designator. To fix this, remove the ${error.data.detail} designator in Luminovo and import again.`;
                    } else {
                        errorMessage += error.data.detail;
                    }
                }
            }
            errorAndResetBomStateCallback(errorMessage);
        },
    });

    function closeErrorsInBomWarningModal() {
        setIsErrorsInBomWarningModalOpen(false);
    }

    React.useEffect(() => {
        const importBomCallback = async () => {
            if (hasAtLeastOneErrorInBom) {
                return;
            }
            if (hasSuggestionsFromPrevBomImport) {
                return;
            }
            try {
                await mutateAsync({ acceptPrevBomLines: false });
                // eslint-disable-next-line no-empty
            } catch (error) {}
        };
        importBomCallback();
    }, [token, hasAtLeastOneErrorInBom, hasSuggestionsFromPrevBomImport, mutateAsync]);

    return (
        <Dialog
            fullScreen
            maxWidth={'md'}
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            TransitionComponent={Transition}
            disableEscapeKeyDown={true}
            sx={{
                paperFullScreen: {
                    backgroundColor: colorSystem.neutral[1],
                    overflow: 'hidden',
                },
            }}
        >
            <DialogAppBar
                title={<DialogAppBarTitle isReusedColumnMapping={bomScreeningSheet.reused_column_map} />}
                onClose={onClose}
                actionButton={<></>}
                informationLabel={
                    hasAtLeastOneErrorInBom ? (
                        <Box mr={1} display={'flex'} alignItems={'center'}>
                            <WarningRounded style={{ color: colorSystem.red[6] }} />
                            <Typography style={{ color: colorSystem.red[6] }} noWrap>
                                <Trans>You cannot edit or import the BOM because it contains errors.</Trans>
                            </Typography>
                        </Box>
                    ) : undefined
                }
            />

            {isLoading && (
                <CenteredLayout>
                    <SpinnerWithBackdrop noBackdrop={true} />
                </CenteredLayout>
            )}

            {isErrorsInBomWarningModalOpen && (
                <InformationDialogBox
                    title={
                        <Box display={'flex'} alignItems={'center'}>
                            <WarningRounded style={{ color: colorSystem.red[6], marginRight: 8 }} />
                            <Trans>BOM contains errors</Trans>
                        </Box>
                    }
                    isDialogOpen={isErrorsInBomWarningModalOpen}
                    onReject={closeErrorsInBomWarningModal}
                    buttonText={'OK'}
                >
                    <Flexbox flexDirection={'column'} gap={8}>
                        <Typography>
                            <Trans>
                                You cannot edit or import the BOM because it contains errors. Please fix the original
                                file and upload it again.
                            </Trans>
                        </Typography>

                        {allLineIssues.map((issue, i) => {
                            return <BomIssueItem key={i} issue={issue} />;
                        })}
                    </Flexbox>
                </InformationDialogBox>
            )}

            <Dialog
                open={hasSuggestionsFromPrevBomImport}
                PaperProps={{ style: { padding: '12px', maxWidth: '624px' } }}
            >
                <DialogTitle title={t`Identical Excel lines from previous import`} />
                <DialogContent style={{ paddingBottom: 0 }}>
                    <Flexbox>
                        <Text style={{ whiteSpace: 'pre-line' }}>
                            {plural(countBomItemsWithChangesFromPrevBomImport, {
                                one: `Would you like to add ${countSuggestionsFromPrevBomImport} additional part option in ${countBomItemsWithChangesFromPrevBomImport} BOM item for matching lines from a previous import? 
                                {br}
                                After the import you can filter for BOM items that match lines from a previous import and have had part options manually added, removed, or modified.
                                {br}
                                We automatically sync the approval status or remove part options based on previous imports in any case.`,
                                other: `Would you like to add ${countSuggestionsFromPrevBomImport} additional part options in ${countBomItemsWithChangesFromPrevBomImport} BOM items for matching lines from a previous import?
                                {br}
                                After the import you can filter for BOM items that match lines from a previous import and have had part options manually added, removed, or modified.
                                {br}
                                We automatically sync the approval status or remove part options based on previous imports in any case.`,
                            })}
                        </Text>
                    </Flexbox>
                </DialogContent>
                <DialogActions>
                    <SecondaryButton
                        onClick={() => mutateAsync({ acceptPrevBomLines: false })}
                        size="medium"
                        disabled={isLoading}
                    >
                        <Trans>No, keep in suggestions</Trans>
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => mutateAsync({ acceptPrevBomLines: true })}
                        size="medium"
                        disabled={isLoading}
                    >
                        <Trans>Add parts</Trans>
                    </PrimaryButton>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

export interface BomImportEventState {
    imported: true;
    columnMap: ColumnMap;
}
