import { QuoteRequestLineItem } from '../hooks/queries';

export function getQuoteRequestLineItemStatus(quoteRequestLineItem: QuoteRequestLineItem) {
    if (!quoteRequestLineItem.quoteRequest) {
        return 'not-sent';
    }
    if (quoteRequestLineItem.quoteRequest.sent_date) {
        return 'sent';
    }
    if (quoteRequestLineItem.quoteRequest.received_date) {
        return 'received';
    }
    return 'not-sent';
}
