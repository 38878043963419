/* eslint-disable spellcheck/spell-checker */
import { Trans } from '@lingui/macro';
import { Flexbox, Text, Tooltip, TooltipProps } from '@luminovo/design-system';
import { LifecycleEnum, OffTheShelfPartVote, OTSPartOriginEnum } from '@luminovo/http-client';
import { Skeleton } from '@mui/material';
import { useCallback, useState } from 'react';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { LifecycleChipWithTooltip } from './LifecycleChipWithTooltip';

export const OtsPartLifecycleStatusOriginsTooltip: React.FunctionComponent<{
    partId: string;
    lifecycleStatus: LifecycleEnum;
    children: TooltipProps['children'];
}> = ({ partId, lifecycleStatus, children }): JSX.Element => {
    const [votes, setVotes] = useState<OffTheShelfPartVote[] | undefined>();

    const { mutateAsync: fetchVotes, isLoading } = useHttpMutation('GET /parts/off-the-shelf/:partId/votes', {
        snackbarMessage: null,
    });

    const handleOpen = useCallback(async () => {
        if (lifecycleStatus === LifecycleEnum.Unknown) return;
        if (!votes) {
            const response = await fetchVotes({ pathParams: { partId } });
            setVotes(response.items);
        }
    }, [votes, fetchVotes, partId, lifecycleStatus]);

    if (isLoading) {
        return (
            <Tooltip
                arrow
                title={
                    <Flexbox flexDirection="column" gap="16px" width="200px" padding="4px">
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Skeleton width="75%" />
                    </Flexbox>
                }
                variant="white"
            >
                <span>{children}</span>
            </Tooltip>
        );
    }

    const showTooltip = votes && votes.length > 0 && votes.some((origin) => origin.lifecycle);

    return (
        <Tooltip
            arrow
            onOpen={handleOpen}
            title={
                showTooltip ? (
                    <Flexbox flexDirection="column" gap="16px" width="220px" padding="4px">
                        <Text variant="h5">
                            <Trans>Lifecycle origins</Trans>
                        </Text>
                        <Flexbox flexDirection="column" gap="8px">
                            {votes.map((origin, i) => {
                                if (!origin.lifecycle || origin.lifecycle === LifecycleEnum.Unknown) {
                                    return null;
                                }
                                const label = otsPartOriginKeyWithLabelValue[origin.origin.type].label;
                                return (
                                    <Flexbox key={i} alignItems="center" justifyContent="space-between" gap="4px">
                                        <LifecycleChipWithTooltip status={origin.lifecycle} disabledTooltip={true} />
                                        <Text variant="body-small" showEllipsis>
                                            {label}
                                        </Text>
                                    </Flexbox>
                                );
                            })}
                        </Flexbox>
                    </Flexbox>
                ) : (
                    ''
                )
            }
            variant="white"
        >
            <span>{children}</span>
        </Tooltip>
    );
};

export const otsPartOriginKeyWithLabelValue: Record<OTSPartOriginEnum, { label: string }> = {
    [OTSPartOriginEnum.Manual]: { label: 'Manual' },
    [OTSPartOriginEnum.Import]: { label: 'Import' },
    [OTSPartOriginEnum.Aggregate]: { label: 'Aggregate' },
    [OTSPartOriginEnum.DescriptionExtraction]: { label: 'DescriptionExtraction' },
    // ----
    [OTSPartOriginEnum.ArrowAPI]: { label: 'Arrow' },
    [OTSPartOriginEnum.AvnetAPI]: { label: 'Avnet' },
    [OTSPartOriginEnum.AvnetUsaAPI]: { label: 'Avnet USA' },
    [OTSPartOriginEnum.BuerklinAPI]: { label: 'Bürklin' },
    [OTSPartOriginEnum.ChipAssistAPI]: { label: 'Chip Assist' },
    [OTSPartOriginEnum.DigikeyAPI]: { label: 'DigiKey' },
    [OTSPartOriginEnum.Element14API]: { label: 'element14' },
    [OTSPartOriginEnum.EveAPI]: { label: 'EVE' },
    [OTSPartOriginEnum.FarnellAPI]: { label: 'Farnell' },
    [OTSPartOriginEnum.FutureAPI]: { label: 'Future Electronics' },
    [OTSPartOriginEnum.GudecoAPI]: { label: 'Gudeco' },
    [OTSPartOriginEnum.HeilindAPI]: { label: 'Heilind' },
    [OTSPartOriginEnum.IHSImport]: { label: 'Accuris (IHS)' },
    [OTSPartOriginEnum.MasterAPI]: { label: 'Master Electronics' },
    [OTSPartOriginEnum.MouserAPI]: { label: 'Mouser' },
    [OTSPartOriginEnum.MyArrowAPI]: { label: 'MyArrow' },
    [OTSPartOriginEnum.LcscAPI]: { label: 'LCSC' },
    [OTSPartOriginEnum.NewarkAPI]: { label: 'Newark' },
    [OTSPartOriginEnum.OctopartAPI]: { label: 'Octopart' },
    [OTSPartOriginEnum.OnlineComponentsAPI]: { label: 'Online Components' },
    [OTSPartOriginEnum.RsComponentsAPI]: { label: 'RS Components NEW' }, // TODO check
    [OTSPartOriginEnum.RochesterAPI]: { label: 'Rochester Electronics' },
    [OTSPartOriginEnum.QuestComponentsAPI]: { label: 'Quest Components' },
    [OTSPartOriginEnum.RutronikAPI]: { label: 'Rutronik' },
    [OTSPartOriginEnum.SchukatAPI]: { label: 'Schukat' },
    [OTSPartOriginEnum.SosAPI]: { label: 'SOS Electronic' },
    [OTSPartOriginEnum.SourcengineAPI]: { label: 'Sourcengine' },
    [OTSPartOriginEnum.TiAPI]: { label: 'Texas Instruments' },
    [OTSPartOriginEnum.TmeAPI]: { label: 'TME' },
    [OTSPartOriginEnum.TrustedPartsAPI]: { label: 'TrustedParts' },
    [OTSPartOriginEnum.VenkelAPI]: { label: 'Venkel' },
    [OTSPartOriginEnum.WeltronAPI]: { label: 'Weltron' },
    [OTSPartOriginEnum.WuerthEisosAPI]: { label: 'Würth Elektronik eiSos' },
};
