/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { formatMonetaryValue } from '@luminovo/commons';
import { createColumnHelper, SecondaryButton, TanStackTable, useTanStackTable } from '@luminovo/design-system';
import { MonetaryValueBackendRuntype } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { route } from '../../../utils/routes';
import { QuoteRequestLineItem } from '../hooks/queries';
import { formatNegotiationPart } from '../model/formatNegotiationPart';
import { getQuoteRequestLineItemRequestedPart } from '../model/getQuoteRequestLineItemRequestedPart';
import { getQuoteRequestLineItemStatus } from '../model/getQuoteRequestLineItemStatus';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { NegotiationPartLabel } from './NegotiationPartLabel';

export enum ColumnId {
    Supplier = 'supplier',
    BomItem = 'bomItem',
    IPN = 'ipn',
    RequestedPart = 'requestedPart',
    RequiredQuantity = 'requiredQuantity',
    PotentialQuantity = 'potentialQuantity',
    TargetPrice = 'targetPrice',
    OfferedPart = 'offeredPart',
    UnitPrice = 'unitPrice',
    Result = 'result',
    Status = 'status',
    Actions = 'actions',
}

export function TableQuoteRequestLineItems({
    lineItems,
    negotiationId,
    enableRowClick = false,
    enabledColumns,
}: {
    lineItems: QuoteRequestLineItem[];
    negotiationId: number;
    enableRowClick?: boolean;
    enabledColumns: Array<ColumnId>;
}) {
    const history = useHistory();
    const helper = createColumnHelper<QuoteRequestLineItem>();

    const { table } = useTanStackTable({
        enableColumnOrdering: true,
        enableColumnHiding: true,
        onRowClick: enableRowClick
            ? (row) => {
                  history.push(
                      route('/negotiations/:negotiationId/quote-requests/:quoteRequestId', {
                          negotiationId,
                          quoteRequestId: row.original.quote_request_id,
                      }),
                  );
              }
            : undefined,
        data: lineItems,
        columns: [
            helper.text((row) => row.quoteRequest?.supplier?.name ?? t`Unknown`, {
                id: ColumnId.Supplier,
                label: () => t`Supplier`,
                size: 150,
            }),

            helper.text((row) => '', {
                id: ColumnId.BomItem,
                label: () => t`BOM item`,
                size: 150,
            }),

            helper.text('ipn_id', {
                id: ColumnId.IPN,
                label: () => t`IPN`,
                size: 150,
                cell: ({ row }) => {
                    return <NegotiationIpnLabel ipn={row.original.ipn_id} />;
                },
            }),

            helper.text((row) => formatNegotiationPart(getQuoteRequestLineItemRequestedPart(row)), {
                id: ColumnId.RequestedPart,
                label: () => t`Requested part`,
                size: 240,
                cell: function Cell({ row }) {
                    const part = getQuoteRequestLineItemRequestedPart(row.original);
                    return <NegotiationPartLabel part={part} />;
                },
            }),

            helper.text((row) => formatQuantity(row.required_quantity, { showPiecesUnit: true }), {
                id: ColumnId.RequiredQuantity,
                label: () => t`Qty`,
                size: 100,
            }),

            helper.text((row) => formatQuantity(row.potential_quantity ?? 0, { showPiecesUnit: true }), {
                id: ColumnId.PotentialQuantity,
                size: 100,
                label: () => t`Potential Qty`,
            }),

            helper.monetaryValue((row) => row.target_price, {
                id: ColumnId.TargetPrice,
                label: () => t`Target price`,
                size: 150,

                cell: ({ row }) => {
                    return formatMonetaryValue(
                        MonetaryValueBackendRuntype.optional().nullable().check(row.original.target_price),
                        'unit-price',
                        { ifAbsent: '-' },
                    );
                },
            }),

            helper.text((row) => '', {
                id: ColumnId.OfferedPart,
                label: () => t`Offered part`,
                size: 100,
                cell: ({ row }) => {
                    return 'TODO';
                },
            }),

            helper.monetaryValue((row) => {}, {
                id: ColumnId.UnitPrice,
                label: () => t`Unit price`,
                size: 100,
            }),

            helper.monetaryValue((row) => {}, {
                id: ColumnId.Result,
                label: () => t`Result`,
                size: 100,
            }),

            helper.enum((row) => getQuoteRequestLineItemStatus(row), {
                id: ColumnId.Status,
                label: () => t`Status`,
                size: 100,
                getOptionLabel: (value) => {
                    return value;
                },
                initialPinning: 'right',
            }),

            helper.action({
                id: ColumnId.Actions,
                size: 100,
                align: 'right',

                enablePinning: true,
                cell: function Cell() {
                    const { mutateAsync, isLoading } = useMutation({
                        mutationFn: () => {
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve(true);
                                }, 1000);
                            });
                        },
                    });

                    const handleClick = () => {
                        mutateAsync();
                    };
                    return (
                        <SecondaryButton onClick={handleClick} isLoading={isLoading} size="medium">
                            <Trans>Select</Trans>
                        </SecondaryButton>
                    );
                },
            }),
        ].filter((column) => enabledColumns.map((c) => c.toString()).includes(column.id ?? '')),
    });

    return <TanStackTable table={table} />;
}
