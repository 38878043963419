import { Trans } from '@lingui/macro';
import { formatInteger, isPresent } from '@luminovo/commons';
import { ButtonGroup, ButtonGroupItem, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import {
    DemandParticipantRoleDTO,
    DemandSummaryDTO,
    DemandTimeframeTypeDTO,
    DemandTypeDTO,
    RecipientDemandSummaryDTO,
    SupplierDemandSummaryDTO,
} from '@luminovo/http-client';
import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import { DemandSummaryTableDynamic } from './DemandSummaryTable';
import { getParticipantNameFromSummary } from './getParticipantName';
import { SiteNumberMap, SupplierNumberMap, useParticipantNumberMap } from './useParticipantNumberMap';

type ParticipantRoleButtonGroup = DemandParticipantRoleDTO;
type DemandTimeframeTypeButtonGroup = DemandTimeframeTypeDTO;
type DemandTypeButtonGroup = DemandTypeDTO;

export type ButtonGroupsReturnType = {
    participantRole: ParticipantRoleButtonGroup;
    handleParticipantRoleClick: ((newValue: ParticipantRoleButtonGroup) => void) | undefined;
    demandTimeframeType: DemandTimeframeTypeButtonGroup;
    handleDemandTimeframeTypeClick: (newValue: DemandTimeframeTypeButtonGroup) => void;
    demandType: DemandTypeButtonGroup;
    handleDemandTypeClick: (newValue: DemandTypeButtonGroup) => void;
};

export const useButtonGroups = ({
    overrideParticipantRole,
}: {
    overrideParticipantRole?: DemandParticipantRoleDTO;
}): ButtonGroupsReturnType => {
    const [participantRole, setParticipantRole] = useState<ParticipantRoleButtonGroup>(
        overrideParticipantRole ?? 'supplier',
    );
    const handleParticipantRoleClick = (newValue: ParticipantRoleButtonGroup) => {
        setParticipantRole(newValue);
    };

    const [demandTimeframeType, setDemandTimeframeType] = useState<DemandTimeframeTypeButtonGroup>('monthly');
    const handleDemandTimeframeTypeClick = (newValue: DemandTimeframeTypeButtonGroup) => {
        setDemandTimeframeType(newValue);
    };

    const [demandType, setDemandType] = useState<DemandTypeButtonGroup>('net');
    const handleDemandTypeClick = (newValue: DemandTypeButtonGroup) => {
        setDemandType(newValue);
    };

    return {
        participantRole,
        handleParticipantRoleClick: isPresent(overrideParticipantRole) ? undefined : handleParticipantRoleClick,
        demandTimeframeType,
        handleDemandTimeframeTypeClick,
        demandType,
        handleDemandTypeClick,
    };
};

export const ButtonGroups = ({
    buttonGroupsReturnType,
}: {
    buttonGroupsReturnType: ButtonGroupsReturnType;
}): JSX.Element => {
    const {
        participantRole,
        handleParticipantRoleClick,
        demandTimeframeType,
        handleDemandTimeframeTypeClick,
        demandType,
        handleDemandTypeClick,
    } = buttonGroupsReturnType;

    return (
        <Flexbox justifyContent={'space-between'}>
            <Flexbox gap={'20px'}>
                {isPresent(handleParticipantRoleClick) && (
                    <ButtonGroup size="small">
                        <ButtonGroupItem
                            key={'supplier'}
                            selected={participantRole === 'supplier'}
                            onClick={() => handleParticipantRoleClick('supplier')}
                        >
                            <Trans>Supplier</Trans>
                        </ButtonGroupItem>
                        <ButtonGroupItem
                            key={'recipient'}
                            selected={participantRole === 'recipient'}
                            onClick={() => handleParticipantRoleClick('recipient')}
                        >
                            <Trans>Recipient</Trans>
                        </ButtonGroupItem>
                    </ButtonGroup>
                )}
                <ButtonGroup size="small">
                    <ButtonGroupItem
                        key={'yearly'}
                        selected={demandTimeframeType === 'yearly'}
                        onClick={() => handleDemandTimeframeTypeClick('yearly')}
                    >
                        <Trans>Yearly</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem
                        key={'monthly'}
                        selected={demandTimeframeType === 'monthly'}
                        onClick={() => handleDemandTimeframeTypeClick('monthly')}
                    >
                        <Trans>Monthly</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
                <ButtonGroup size="small">
                    <ButtonGroupItem
                        key={'net'}
                        selected={demandType === 'net'}
                        onClick={() => handleDemandTypeClick('net')}
                    >
                        <Trans>Net</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem
                        key={'gross'}
                        selected={demandType === 'gross'}
                        onClick={() => handleDemandTypeClick('gross')}
                    >
                        <Trans>Gross</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
            </Flexbox>
        </Flexbox>
    );
};

const ParticipantsHorizontalList = ({
    participantDemands,
    demandType,
    siteNumberMap,
    supplierNumberMap,
}: {
    participantDemands: (SupplierDemandSummaryDTO | RecipientDemandSummaryDTO)[];
    demandType: DemandTypeDTO;
    siteNumberMap: SiteNumberMap;
    supplierNumberMap: SupplierNumberMap;
}) => {
    return (
        <Flexbox gap={'40px'}>
            {participantDemands.map((participant) => {
                const participantName = getParticipantNameFromSummary(participant, siteNumberMap, supplierNumberMap);
                return (
                    <Flexbox key={participantName} flexDirection={'column'}>
                        <Flexbox alignItems={'center'} gap={4}>
                            <Text variant="body-small" color={colorSystem.neutral[8]}>
                                {participantName}
                            </Text>
                        </Flexbox>
                        <Text variant="h4" color={colorSystem.neutral[9]}>
                            {formatInteger(
                                demandType === 'gross'
                                    ? participant.total_gross_demand.quantity
                                    : participant.total_net_demand.quantity,
                            )}
                        </Text>
                    </Flexbox>
                );
            })}
        </Flexbox>
    );
};

export const DemandSummary = ({
    buttonGroups,
    demandSummary,
}: {
    buttonGroups: ButtonGroupsReturnType;
    demandSummary: DemandSummaryDTO | undefined;
}) => {
    const { siteNumberMap, supplierNumberMap } = useParticipantNumberMap();

    const participantsExist =
        isPresent(demandSummary) &&
        (demandSummary.participant_role === 'supplier'
            ? demandSummary.supplier_demands.length > 0
            : demandSummary.recipient_demands.length > 0);

    return (
        <Flexbox
            sx={{
                backgroundColor: colorSystem.neutral.white,
                paddingY: '24px',
                paddingX: '20px',
            }}
            flexDirection={'column'}
            gap={'16px'}
        >
            <Flexbox>
                <Text color={colorSystem.neutral[8]} variant="h3">
                    <Trans>Summary</Trans>
                </Text>
            </Flexbox>
            <ButtonGroups buttonGroupsReturnType={buttonGroups} />
            {demandSummary && isPresent(siteNumberMap) && isPresent(supplierNumberMap) ? (
                <>
                    <Flexbox flexDirection={'column'}>
                        <Text color={colorSystem.neutral[7]} variant="h5">
                            <Trans>Total demand</Trans>
                        </Text>
                        <Text color={colorSystem.neutral[9]} variant="h1">
                            {formatInteger(
                                buttonGroups.demandType === 'gross'
                                    ? demandSummary.total_gross_demand.quantity
                                    : demandSummary.total_net_demand.quantity,
                            )}
                        </Text>
                    </Flexbox>

                    <ParticipantsHorizontalList
                        participantDemands={
                            demandSummary.participant_role === 'supplier'
                                ? demandSummary.supplier_demands
                                : demandSummary.recipient_demands
                        }
                        demandType={buttonGroups.demandType}
                        siteNumberMap={siteNumberMap}
                        supplierNumberMap={supplierNumberMap}
                    />

                    {participantsExist && (
                        <Box sx={{ height: '200px', marginTop: '28px' }}>
                            <DemandSummaryTableDynamic
                                demandSummary={demandSummary}
                                demandType={buttonGroups.demandType}
                                siteNumberMap={siteNumberMap}
                                supplierNumberMap={supplierNumberMap}
                            />
                        </Box>
                    )}
                </>
            ) : (
                <Flexbox flexDirection={'column'} gap={12}>
                    <Skeleton variant="rectangular" height={50} width={200} />
                    <Skeleton variant="rectangular" height={100} />
                </Flexbox>
            )}
        </Flexbox>
    );
};
