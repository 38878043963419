import { NegotiationPart } from '../hooks/queries';

export function formatNegotiationPart(part?: NegotiationPart): string | undefined {
    if (!part) {
        return undefined;
    }
    if (part.kind === 'off_the_shelf') {
        return `${part.manufacturer_name}, ${part.mpn}`;
    }
    if (part.kind === 'component') {
        return part.ipn_id;
    }
    if (part.kind === 'custom') {
        return `Custom ${Object.values(part.part_type).join(' ')} ${part.description}`;
    }
    if (part.kind === 'custom_component') {
        return part.id;
    }
    // TODO(negotiations): add proper formatting for generic part
    return JSON.stringify(part.content);
}
