/* eslint-disable camelcase */
import { from } from '@luminovo/http-client';
import { col } from '@synthql/queries';
import { UseQueryResult } from '@tanstack/react-query';
import { useSynth } from '../../../components/contexts/SynthQLContext';
import { AwardScenario } from './queries';

export function useAwardScenarios(negotiationId: number): UseQueryResult<AwardScenario[]> {
    const awardedOffers = from('awarded_offer')
        .columns('id')
        .where({
            award_scenario_id: col('award_scenario.id'),
        })
        .all();

    const query = from('award_scenario')
        .columns('id', 'name')
        .where({ negotiation_id: negotiationId })
        .include({
            awardedOffers,
        })
        .all();

    return useSynth({ query });
}
