/* eslint-disable spellcheck/spell-checker */

import { Trans } from '@lingui/macro';
import { intersection, isEqual, isPresent, sortBy, uniq } from '@luminovo/commons';
import { Card, Flexbox, PrimaryButton, SecondaryButton, StickyLayout } from '@luminovo/design-system';
import {
    BomItemApprovalStatus,
    ComplianceStatus,
    DesignItemOriginDTO,
    DesignItemOriginTypes,
    LifecycleEnum,
    PartCountEnum,
    QuantityUnit,
} from '@luminovo/http-client';
import { FilterListRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { ColumnFiltersState } from '@tanstack/react-table';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../../components/PageLayout';
import { SearchInput } from '../../components/SearchInput';
import { SpinnerWithBackdrop } from '../../components/Spinners';
import { transEnum } from '../../components/localization/TransEnum';
import { useAssembly } from '../../resources/assembly/assemblyHandler';
import { BomItem } from '../../resources/designItem/bomItemFrontendTypes';
import { isPcbDesignItem } from '../../resources/designItem/designItemFunctions';
import { PartSpecificationTypes } from '../../resources/part/PartSpecificationTypes';
import { indexFullPart } from '../../resources/part/indexFullPart';
import { assemblyTypePublicTranslations } from '../../resources/rfq/i18n';
import { useRfQ } from '../../resources/rfq/rfqHandler';
import { colorSystem } from '../../themes';
import { analytics } from '../../utils/analytics';
import { id } from '../../utils/ids';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { QueryParams, UrlParams, route } from '../../utils/routes';
import { assertUnreachable } from '../../utils/typingUtils';
import { useBomOverviewTable } from '../Bom/components/BomHealthDashboard/BomOverviewTable/BomOverview';
import { newDesignItemId } from '../Bom/components/ImportBomButton';
import {
    AssemblyTableData,
    BomItemTableData,
    ModuleTableData,
    ViewContext,
    isAssemblyTableData,
    isBomItemTableData,
    useFilterModulesByAppliedFilters,
} from '../Bom/components/ModuleTableData';
import { FilterId, useBomFilters } from '../Bom/components/ModuleTableData/filters';
import { useBOMTableData } from '../Bom/components/ModuleTableData/useBOMTableData';
import { ManufacturingIssues } from '../Bom/hooks/utils/bomIssues';
import { BomImportEventState } from '../BomImporter/BomImporterLinesDialog';
import { BomItemForm, PCBDesignItemForm } from './components/BomItemForm';
import { BomItemSummary, MainContentContainer } from './components/BomItemSummary/BomItemSummary';
import { BomLinePreviews } from './components/BomLinePreviews';
import { FilterIssuesInBomForm, useTrackFiltersEvents } from './components/FilterIssuesInBomForm';
import { ItemDetailsLayout } from './components/ItemDetailsLayout';
import { StatusFilters } from './components/StatusFilters';
import { ToolbarBomEditPage } from './components/ToolbarBomEditPage';
import { WarningsAccordion } from './components/WarningsAccordion';
import { WarningSubCategoryBomItemIssues } from './countWarningsAcrossBomItems';
import { Direction, useKeyUpDown } from './useKeyUpDown';

// write unit tests
function getNextSelectedBomItemId({
    direction,
    selectedModuleId,
    filteredModules,
}: {
    direction: Direction;
    selectedModuleId: string[] | string | undefined;
    filteredModules: ModuleTableData[];
}) {
    const currentIndex = selectedModuleId
        ? filteredModules.findIndex((data) => {
              return isAssemblyTableData(data) ? data.id === selectedModuleId[0] : isEqual(data.id, selectedModuleId);
          })
        : 0;

    const positionDelta = direction === 'ArrowDown' ? 1 : direction === 'ArrowUp' ? -1 : 0;

    const upperBound = Math.min(currentIndex + positionDelta, filteredModules.length);

    const nextIndex = Math.max(0, upperBound);

    const nextBomItem = filteredModules[nextIndex];

    if (!nextBomItem) {
        return selectedModuleId;
    }

    return nextBomItem.id;
}

type FilterBomModulesProps = {
    modules?: ModuleTableData[];
    filters: {
        selectedStatus?: BomItemApprovalStatus;
        searchedText?: string;
        appliedFilters: Set<FilterId>;
        warningSubCategoryIssues: Set<WarningSubCategoryBomItemIssues>;
        onlyShowItemsWithManufacturingWarnings: boolean;
    };
    filteredByDashboardFilters: ModuleTableData[];
};

function parseInitialHealthDashboardFilters(search: string): ColumnFiltersState {
    const queryParams = new URLSearchParams(search);
    const paramValue = queryParams.get('dashboardFilters');
    if (!paramValue) {
        return [];
    }
    try {
        const searchBlocks: ColumnFiltersState = JSON.parse(paramValue);
        if (!Array.isArray(searchBlocks)) {
            return [];
        }
        return searchBlocks;
    } catch (e) {
        return [];
    }
}

const useDashboardFilters = ({
    modules = [],
    urlSearch,
    assemblyId,
    viewContext,
}: {
    modules?: ModuleTableData[];
    urlSearch: string;
    assemblyId: string;
    viewContext: ViewContext;
}) => {
    const healthDashboardFilters = React.useMemo(() => parseInitialHealthDashboardFilters(urlSearch), [urlSearch]);

    const { table } = useBomOverviewTable({
        items: modules,
        isBomMainPage: false,
        assemblyId,
        viewContext,
        columnFilters: healthDashboardFilters,
    });

    return React.useMemo(() => {
        return table
            .getFilteredRowModel()
            .rows.map((row) => {
                if (row.original.type === 'row') {
                    return row.original.moduleTableData;
                }
                return null;
            })
            .filter(isPresent);
    }, [table]);
};

const useFilterBomModules = ({
    modules = [],
    filters: {
        selectedStatus,
        searchedText,
        appliedFilters,
        warningSubCategoryIssues,
        onlyShowItemsWithManufacturingWarnings,
    },
    filteredByDashboardFilters,
}: FilterBomModulesProps) => {
    const modulesFilteredByFilterId = useFilterModulesByAppliedFilters(modules, appliedFilters);

    const areDashboardFiltersSelected = appliedFilters.has(FilterId.HealthDashboardFilters);
    const result = React.useMemo(() => {
        return areDashboardFiltersSelected
            ? uniq([...modulesFilteredByFilterId, ...filteredByDashboardFilters])
            : modulesFilteredByFilterId;
    }, [modulesFilteredByFilterId, filteredByDashboardFilters, areDashboardFiltersSelected]);

    const filteredByStatus = React.useMemo(() => {
        return result.filter((module) => {
            if (!selectedStatus && warningSubCategoryIssues.size === 0) {
                return true;
            }
            if (isAssemblyTableData(module)) {
                return selectedStatus === module.approvalStatus;
            }
            if (isBomItemTableData(module)) {
                if (warningSubCategoryIssues.size === 0) return selectedStatus === module.approvalStatus;

                for (const issue of warningSubCategoryIssues) {
                    if (module.issues.includes(issue)) return true;
                }

                return false;
            }
            return assertUnreachable(module);
        });
    }, [result, selectedStatus, warningSubCategoryIssues]);

    const maybeFilterByManufacturingIssues = React.useMemo(() => {
        if (!onlyShowItemsWithManufacturingWarnings) return filteredByStatus;
        // This will only be active when a user is coming from the Manufacturing driver page
        return filteredByStatus.filter((module) => {
            if (isAssemblyTableData(module)) {
                return true;
            }
            const manufacturingIssuesInBomItem = intersection(module.issues, ManufacturingIssues);
            return manufacturingIssuesInBomItem.length > 0 || module.partDataIssues.length > 0;
        });
    }, [filteredByStatus, onlyShowItemsWithManufacturingWarnings]);

    return React.useMemo(() => {
        // indexing is expensive on slow cpus with large boms, so we avoid this when a user is adding a designator
        // which can slow down the UI.
        // As a permanent fix, we can either return these indexed keywords from the backend or perform the indexing in a worker thread in the FE.
        // For now, we only index when the user is searching for a bom-item.
        if (!searchedText)
            return sortBy(maybeFilterByManufacturingIssues, (x) => (x.origin.originalRowNumbers ?? [])[0] ?? 0);
        const filteredBySearchedText = searchModules(maybeFilterByManufacturingIssues, searchedText ?? '');
        return sortBy(filteredBySearchedText, (x) => (x.origin.originalRowNumbers ?? [])[0] ?? 0);
    }, [maybeFilterByManufacturingIssues, searchedText]);
};

type FindSelectedModuleProps = {
    modules: ModuleTableData[];
    selectedModuleId: ModuleTableData['id'];
    assemblyId: string;
    rfqId?: string;
    showPartAlternatives: boolean;
};

const findSelectedModule = ({
    modules,
    rfqId,
    selectedModuleId,
    assemblyId,
    showPartAlternatives,
}: FindSelectedModuleProps): ModuleTableData | undefined => {
    if (isEqual(selectedModuleId, [newDesignItemId])) {
        return createEmptyBomItem({ parentId: assemblyId, rfqId, showPartAlternatives });
    }

    const bomItemMatch = modules.find((c) => {
        return isEqual(c.id, selectedModuleId ?? []);
    });

    if (bomItemMatch) {
        return bomItemMatch;
    }

    const assemblyMatch = modules.find((c) => {
        return isEqual([c.id], selectedModuleId);
    });

    if (assemblyMatch) {
        return assemblyMatch;
    }

    return modules.find((c) => intersection(c.id, selectedModuleId).length > 0);
};

export function BomEditPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>): JSX.Element {
    const rfqId = pathParams.rfqId;
    const assemblyId = pathParams.assemblyId;
    const currentParentAssemblyId = queryParams.currentParentAssemblyId;

    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);

    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <BomDetails
            viewContext={viewContext}
            assemblyId={assemblyId}
            isEditable={isRfqEditable}
            queryParams={queryParams}
            renderToolBar={(selectedBomItem) => (
                <ToolbarBomEditPage
                    assemblyId={pathParams.assemblyId}
                    rfqId={pathParams.rfqId}
                    selectedModule={selectedBomItem}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
        />
    );
}

export function BomDetails({
    viewContext,
    assemblyId,
    isEditable,
    renderToolBar,
    queryParams,
}: {
    viewContext: ViewContext;
    assemblyId: string;
    isEditable: boolean;
    renderToolBar: (selectedBomItem: ModuleTableData | undefined) => JSX.Element;
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
}) {
    const rfqId = viewContext.rfqId;
    const { data: assembly } = useAssembly(assemblyId);
    const currentParentAssemblyId = queryParams.currentParentAssemblyId;
    const [selectedStatus, setSelectedStatus] = React.useState<BomItemApprovalStatus | undefined>();
    const [warningSubCategoryIssues, setWarningSubCategoryIssues] = React.useState<
        Set<WarningSubCategoryBomItemIssues>
    >(new Set()); // for filtering issues within each status, i.e., compliance, lifecycle etc. within the warning status
    const [searchedText, setSearchedText] = React.useState<string>(queryParams.search ?? '');
    const [isFiltersOpen, setIsFiltersOpen] = React.useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useBomFilters(queryParams.filters);
    const history = useHistory<BomImportEventState | undefined>();
    useTrackFiltersEvents(isFiltersOpen, appliedFilters);
    const { moduleData, bomItems, showPartAlternatives } = useBOMTableData({ assemblyId, rfqId });

    const filteredByDashboardFilters = useDashboardFilters({
        urlSearch: history.location.search,
        modules: moduleData,
        assemblyId,
        viewContext,
    });
    const onlyShowItemsWithManufacturingWarnings = queryParams.onlyShowItemsWithManufacturingWarnings === 'true';
    const filteredModules = useFilterBomModules({
        modules: moduleData,
        filters: {
            selectedStatus,
            searchedText,
            appliedFilters,
            warningSubCategoryIssues,
            onlyShowItemsWithManufacturingWarnings,
        },
        filteredByDashboardFilters,
    });

    const selectedModuleId = queryParams.designItemId?.split(',') ?? undefined;

    const selectedBomItem = React.useMemo(() => {
        return selectedModuleId
            ? findSelectedModule({
                  modules: moduleData ?? [],
                  selectedModuleId,
                  assemblyId,
                  rfqId,
                  showPartAlternatives,
              })
            : undefined;
    }, [assemblyId, moduleData, rfqId, selectedModuleId, showPartAlternatives]);
    // Used because there can't be repeated designators
    const siblingBomItems = bomItems?.filter((c) => !isEqual(c.id, selectedBomItem?.id)) ?? [];

    const handleSelectBomItem = React.useCallback(
        (module: ModuleTableData) => {
            if (viewContext.type === 'WithinRfQ')
                return history.replace(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        {
                            rfqId: viewContext.rfqId,
                            assemblyId,
                        },
                        {
                            isReadonly: queryParams.isReadonly,
                            currentParentAssemblyId,
                            designItemId: isAssemblyTableData(module) ? module.id : module.id.join(','),
                            filters: queryParams.filters,
                            bomTab: queryParams.bomTab,
                            dashboardFilters: queryParams.dashboardFilters,
                            search: null,
                            onlyShowItemsWithManufacturingWarnings: queryParams.onlyShowItemsWithManufacturingWarnings,
                        },
                    ),
                );
            return history.replace(
                route(
                    '/assemblies/:assemblyId/details',
                    { assemblyId },
                    {
                        designItemId: isAssemblyTableData(module) ? module.id : module.id.join(','),
                        filters: queryParams.filters,
                        dashboardFilters: queryParams.dashboardFilters,
                        search: null,
                    },
                ),
            );
        },
        [
            viewContext.type,
            viewContext.rfqId,
            history,
            assemblyId,
            queryParams.isReadonly,
            queryParams.filters,
            queryParams.bomTab,
            queryParams.dashboardFilters,
            queryParams.onlyShowItemsWithManufacturingWarnings,
            currentParentAssemblyId,
        ],
    );

    const handleSelectNextModule = React.useCallback(
        (direction: Direction = 'ArrowDown') => {
            const nextSelectedModuleId = getNextSelectedBomItemId({
                direction,
                selectedModuleId,
                filteredModules,
            });

            if (viewContext.type === 'WithinRfQ')
                return history.replace(
                    route(
                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                        { rfqId: viewContext.rfqId, assemblyId },
                        {
                            isReadonly: queryParams.isReadonly,
                            designItemId: Array.isArray(nextSelectedModuleId)
                                ? nextSelectedModuleId.join(',')
                                : (nextSelectedModuleId ?? null),
                            currentParentAssemblyId,
                            filters: null,
                            bomTab: queryParams.bomTab,
                            dashboardFilters: queryParams.dashboardFilters,
                            search: null,
                            onlyShowItemsWithManufacturingWarnings: queryParams.onlyShowItemsWithManufacturingWarnings,
                        },
                    ),
                );

            return history.replace(
                route(
                    '/assemblies/:assemblyId/details',
                    { assemblyId },
                    {
                        designItemId: Array.isArray(nextSelectedModuleId)
                            ? nextSelectedModuleId.join(',')
                            : (nextSelectedModuleId ?? null),
                        filters: null,
                        dashboardFilters: queryParams.dashboardFilters,
                        search: null,
                    },
                ),
            );
        },
        [
            selectedModuleId,
            filteredModules,
            viewContext.type,
            viewContext.rfqId,
            history,
            assemblyId,
            queryParams.isReadonly,
            queryParams.bomTab,
            queryParams.dashboardFilters,
            queryParams.onlyShowItemsWithManufacturingWarnings,
            currentParentAssemblyId,
        ],
    );

    useKeyUpDown(handleSelectNextModule);

    const handleSelectStatus = React.useCallback(
        (newStatus: BomItemApprovalStatus | undefined) => {
            setAppliedFilters(new Set());
            setSelectedStatus(newStatus);
            setWarningSubCategoryIssues(new Set());
            // when the user selects status 'all', display the BomImportSummary
            if (!newStatus) {
                if (viewContext.type === 'WithinRfQ')
                    return history.push({
                        pathname: route(
                            '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                            { assemblyId, rfqId: viewContext.rfqId },
                            {
                                isReadonly: null,
                                designItemId: null,
                                currentParentAssemblyId,
                                filters: null,
                                bomTab: null,
                                dashboardFilters: null,
                                search: null,
                                onlyShowItemsWithManufacturingWarnings: null,
                            },
                        ),
                    });
                return history.push({
                    pathname: route('/assemblies/:assemblyId/details', { assemblyId }),
                });
            }
        },
        [setAppliedFilters, viewContext.type, viewContext.rfqId, history, assemblyId, currentParentAssemblyId],
    );

    const handleSearchChange = React.useCallback(
        (newString: string) => {
            setAppliedFilters(new Set());
            setSearchedText(newString);
        },
        [setAppliedFilters, setSearchedText],
    );

    const clearAppliedFilters = () => {
        setAppliedFilters(new Set());
    };

    const clearSelectedWarningSubStatus = () => {
        setWarningSubCategoryIssues(new Set());
    };

    if (!bomItems) {
        return (
            <PageLayout layout="centered">
                <SpinnerWithBackdrop noBackdrop={true} />
            </PageLayout>
        );
    }
    const filtersStyle =
        isFiltersOpen || appliedFilters.size > 0
            ? {
                  background: colorSystem.primary[2],
                  color: colorSystem.primary[7],
                  border: `1px solid ${colorSystem.primary[3]}`,
              }
            : // eslint-disable-next-line spellcheck/spell-checker
              {};

    return (
        <PageLayout
            removeHubspotPaddingFix
            layout="fragment"
            header={renderToolBar(selectedBomItem)}
            style={{ background: colorSystem.neutral[1] }}
        >
            <ItemDetailsLayout flexGrow={1}>
                <StickyLayout
                    id={id('design/left_sidebar')}
                    maxHeight="calc(100vh - 128px)"
                    padding={'24px 12px 0px 12px'}
                    borderRight={`1px solid ${colorSystem.neutral[2]}`}
                    gap="16px"
                >
                    <StatusFilters
                        items={moduleData}
                        selectedStatus={selectedStatus}
                        onSelectStatus={handleSelectStatus}
                    />
                    <Flexbox
                        gap="16px"
                        flexDirection="column"
                        position={'relative'}
                        id={'design/search_bar_and_filters'}
                    >
                        <SearchInput
                            value={searchedText}
                            onChange={handleSearchChange}
                            autoFocus
                            style={{ width: '100%' }}
                        />
                        <SecondaryButton
                            id="filters-button"
                            style={filtersStyle}
                            onClick={() => setIsFiltersOpen((prev) => !prev)}
                            startIcon={<FilterListRounded />}
                            size="medium"
                        >
                            <Trans>Filters</Trans>
                        </SecondaryButton>
                        <FilterIssuesInBomForm
                            isFiltersOpen={isFiltersOpen}
                            setIsFiltersOpen={setIsFiltersOpen}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                            setSearchedText={setSearchedText}
                            setSelectedStatus={setSelectedStatus}
                            clearSelectedWarningSubStatus={clearSelectedWarningSubStatus}
                            modulesData={moduleData}
                            dashboardFiltersCount={filteredByDashboardFilters.length}
                        />
                    </Flexbox>
                    <WarningsAccordion
                        moduleTableData={moduleData}
                        setSelectedStatus={setSelectedStatus}
                        setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                        clearAppliedFilters={clearAppliedFilters}
                        warningSubCategoryIssues={warningSubCategoryIssues}
                        setSearchedText={setSearchedText}
                    />
                    <Flexbox
                        boxSizing="border-box"
                        flexDirection="column"
                        style={{
                            borderRadius: 4,
                            outline: 'none',
                            flexGrow: 1,
                            pointerEvents: 'auto',
                        }}
                        gap={8}
                    >
                        <BomLinePreviews
                            onClick={handleSelectBomItem}
                            filteredModules={filteredModules}
                            selectedModule={selectedBomItem}
                        />
                    </Flexbox>
                </StickyLayout>
                {selectedModuleId ? (
                    <MainContent
                        key={selectedBomItem?.id.toString()}
                        queryParams={queryParams}
                        viewContext={viewContext}
                        assemblyId={assemblyId}
                        isEditable={isEditable}
                        selectedModule={selectedBomItem}
                        siblingBomItems={siblingBomItems}
                        modules={moduleData}
                        customerId={assembly?.customer ?? undefined}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                ) : (
                    <BomItemSummary
                        moduleTableData={moduleData}
                        selectedStatus={selectedStatus}
                        setSelectedStatus={setSelectedStatus}
                        setWarningSubCategoryIssues={setWarningSubCategoryIssues}
                        clearAppliedFilters={clearAppliedFilters}
                        warningSubCategoryIssues={warningSubCategoryIssues}
                        setSearchedText={setSearchedText}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                    />
                )}
            </ItemDetailsLayout>
        </PageLayout>
    );
}

const MainContent = React.memo(function MainContent({
    queryParams,
    viewContext,
    assemblyId,
    selectedModule,
    siblingBomItems,
    isEditable,
    modules,
    customerId,
    currentParentAssemblyId,
}: {
    queryParams: QueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId/details'>;
    viewContext: ViewContext;
    assemblyId: string;
    selectedModule?: ModuleTableData;
    siblingBomItems: BomItem[];
    isEditable: boolean;
    modules: ModuleTableData[];
    customerId?: string;
    currentParentAssemblyId: string | null | undefined;
}) {
    const history = useHistory();
    const rfqId = viewContext.rfqId;
    if (modules.length === 0 && !selectedModule) {
        return (
            <MainContentContainer>
                <Card height="auto" marginTop="15%" marginBottom="10%" gap={16} textAlign={'center'}>
                    <Typography variant="h1" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>No BOM items</Trans>
                    </Typography>
                    <Typography variant="body1" color={'textPrimary'}>
                        <Trans>There are no BOM items in this assembly</Trans>
                    </Typography>
                    {isEditable && rfqId && (
                        <PrimaryButton
                            id={id('design/button_add_placed_component')}
                            onClick={() => {
                                /* eslint-disable camelcase */
                                analytics.track('create_bom_item', {
                                    rfq_id: rfqId,
                                    assembly_uuid: assemblyId,
                                });
                                /* eslint-enable camelcase */
                                history.push(
                                    route(
                                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                                        { rfqId, assemblyId },
                                        {
                                            isReadonly: null,
                                            designItemId: newDesignItemId,
                                            filters: null,
                                            bomTab: null,
                                            dashboardFilters: null,
                                            search: null,
                                            currentParentAssemblyId,
                                            onlyShowItemsWithManufacturingWarnings: null,
                                        },
                                    ),
                                );
                            }}
                        >
                            <Trans>Add BOM item</Trans>
                        </PrimaryButton>
                    )}
                </Card>
            </MainContentContainer>
        );
    }

    if (!selectedModule) {
        return (
            <MainContentContainer>
                <Card height="auto" style={{ marginTop: '15%', marginBottom: '10%' }}>
                    <Typography variant="h1" color={'textSecondary'}>
                        <Trans>No BOM item selected</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans>Click on any of the items on the left sidebar to select a BOM item.</Trans>
                    </Typography>
                </Card>
            </MainContentContainer>
        );
    }

    if (isAssemblyTableData(selectedModule)) {
        return (
            <MainContentContainer>
                <Card height="auto" style={{ marginTop: '15%', marginBottom: '10%', textAlign: 'center' }}>
                    <Typography variant="h1" style={{ color: colorSystem.neutral[6] }}>
                        <Trans>
                            {transEnum(selectedModule.type, assemblyTypePublicTranslations)} with{' '}
                            {selectedModule.designItems.length} design items
                        </Trans>
                    </Typography>
                    {selectedModule.notes && (
                        <Typography variant="body1" color={'textPrimary'}>
                            <Trans>{selectedModule.notes}</Trans>
                        </Typography>
                    )}

                    <SecondaryButton
                        href={
                            viewContext.type === 'WithinRfQ'
                                ? route(
                                      '/rfqs/:rfqId/bom/assembly/:assemblyId',
                                      { assemblyId: selectedModule.id, rfqId: viewContext.rfqId },
                                      { currentParentAssemblyId: assemblyId, tab: null, monitoring: null },
                                  )
                                : route('/assemblies/:assemblyId/dashboard', { assemblyId })
                        }
                    >
                        <Trans>Go to assembly</Trans>
                    </SecondaryButton>
                </Card>
            </MainContentContainer>
        );
    }

    if (isPcbModule(selectedModule) && rfqId) {
        return (
            <MainContentContainer style={{ minWidth: '1052px' }} padding="24px">
                <PCBDesignItemForm
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    bomItem={selectedModule}
                    isEditable={isEditable}
                    customerId={customerId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </MainContentContainer>
        );
    }

    if (isBomItemTableData(selectedModule)) {
        const uniqueKey = [viewContext.rfqId, assemblyId, isEditable, selectedModule.id.join(',')].join('|');
        return (
            <MainContentContainer
                style={{
                    background: 'unset',
                    minWidth: 'unset',
                    width: 'clamp(1000px, 100%, 1464px)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <BomItemForm
                    key={uniqueKey}
                    queryParams={queryParams}
                    viewContext={viewContext}
                    assemblyId={assemblyId}
                    bomItem={selectedModule}
                    siblingDesignators={siblingBomItems.flatMap((bomItem) => bomItem.designator).sort()}
                    isEditable={isEditable}
                    customerId={customerId}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            </MainContentContainer>
        );
    }

    assertUnreachable(selectedModule);
});

function isPcbModule(selectedModule: ModuleTableData): boolean {
    return isBomItemTableData(selectedModule) && isPcbDesignItem(selectedModule);
}

function indexAssemblyModule(module: AssemblyTableData): string[] {
    return [module.type, module.designator];
}

function indexBomItemModule(module: BomItemTableData): string[] {
    const data = [
        ...module.designator,
        module.notes,
        module.origin.type,
        ...module.individualDesignItems.flatMap((di) => indexDesignItemOrigin(di.origin)),
        ...module.parts.flatMap((p) => indexFullPart(p.part)),
    ];
    return data.filter((v) => v !== '');
}

function indexDesignItemOrigin(designItemOrigin: DesignItemOriginDTO): string[] {
    if (designItemOrigin.type === DesignItemOriginTypes.ExcelFile) {
        return designItemOrigin.data.excel_lines.flatMap((line) =>
            Object.values(line.raw_original_line).flatMap((k) => (k ? k.split(' ') : '')),
        );
    }
    return [];
}

function indexModuleTableData(module: ModuleTableData) {
    if (module.moduleType === 'bomItem') {
        return indexBomItemModule(module);
    } else if (module.moduleType === 'assembly') {
        return indexAssemblyModule(module);
    }
    assertUnreachable(module);
}

function isSearchMatch(module: ModuleTableData, searchText: string): boolean {
    const searchableText: string[] = indexModuleTableData(module);

    for (const text of searchableText) {
        if (text.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
    }
    return false;
}

function searchModules(modules: ModuleTableData[], searchText: string): ModuleTableData[] {
    return modules.filter((module) => isSearchMatch(module, searchText));
}

function createEmptyBomItem({
    parentId,
    rfqId,
    showPartAlternatives,
}: {
    parentId: string;
    rfqId?: string;
    showPartAlternatives: boolean;
}): BomItemTableData {
    return {
        moduleType: 'bomItem',
        rfqId,
        id: [],
        designator: [],
        quantity: { quantity: 1, unit: QuantityUnit.Pieces },
        doNotPlace: false,
        notes: '',
        parentId,
        optionIds: [],
        specification: {
            type: PartSpecificationTypes.OffTheShelf,
            data: {
                // eslint-disable-next-line camelcase
                is_manufacturer_free: false,
                // eslint-disable-next-line camelcase
                part_options: [],
            },
        },
        parts: [],
        approvedPartOptions: [],
        origin: { type: DesignItemOriginTypes.Manual },
        individualDesignItems: [],
        isConsigned: false,
        ignorePackageNameMismatch: false,
        // using same defaults as the backend
        reachCompliant: ComplianceStatus.Unknown,
        rohsCompliant: ComplianceStatus.Unknown,
        lifecycleStatus: LifecycleEnum.Unknown,
        availability: null,
        showPartAlternatives,
        yearsToEndOfLife: null,
        approvedPartOptionCardinality: PartCountEnum.None,
        filterIds: new Set(),
        approvalStatus: BomItemApprovalStatus.Approved,
        issues: [],
        partDataIssues: [],
        generalProperties: [],
        emissionData: null,
    };
}
