import { PrimaryButton } from '@luminovo/design-system';
import { QuoteRequest } from '../hooks/queries';
import { getQuoteRequestStatus } from '../model/getQuoteRequestStatus';

export function ButtonSendQuoteRequests({ quoteRequests }: { quoteRequests: QuoteRequest[] }) {
    const sendable = quoteRequests.filter((s) => {
        const status = getQuoteRequestStatus(s);
        return status === 'not-sent';
    });

    return (
        <PrimaryButton size="medium" disabled={sendable.length === 0} onClick={() => {}}>
            {sendable.length > 1 ? 'Send requests' : 'Send request'}
        </PrimaryButton>
    );
}
