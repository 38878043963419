import { AwardScenario } from '../hooks/queries';

export function formatAwardScenario(awardScenario?: AwardScenario) {
    if (!awardScenario) {
        return '';
    }

    if (awardScenario.name === 'luminovo::best-price-across-all-quotes') {
        return 'Best price across all quotes';
    }
    if (awardScenario.name === 'luminovo::last-purchase-price') {
        return 'Last purchase price';
    }
    if (awardScenario.name === 'luminovo::best-list-price') {
        return 'Best list price';
    }
    if (awardScenario.name === 'luminovo::random-list-price') {
        return 'Random list price';
    }
    return awardScenario.name;
}
