import { SecondaryButton } from '@luminovo/design-system';
import { Download } from '@mui/icons-material';
import { useDownloadQuoteRequestExcel } from '../../../resources/export/exportHandler';
import { useQuoteRequest } from '../hooks/useQuoteRequest';

export function ButtonDownloadQuoteRequestExcel({ quoteRequestId }: { quoteRequestId: number }) {
    const { mutateAsync, isLoading } = useDownloadQuoteRequestExcel(quoteRequestId);

    const { data: quoteRequest } = useQuoteRequest({ quoteRequestId });

    if (!quoteRequest) {
        return null;
    }

    return (
        <SecondaryButton size="medium" startIcon={<Download />} isLoading={isLoading} onClick={() => mutateAsync()}>
            Download Excel
        </SecondaryButton>
    );
}
