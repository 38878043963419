import {
    DemandResponseParticipantDTO,
    RecipientDemandSummaryDTO,
    SupplierDemandSummaryDTO,
} from '@luminovo/http-client';
import { SiteNumberMap, SupplierNumberMap } from './useParticipantNumberMap';

export function getParticipantName(
    demandResponseParticipant: DemandResponseParticipantDTO,
    siteNumberMap: SiteNumberMap,
    supplierNumberMap: SupplierNumberMap,
): string | undefined {
    // this can be undefined even though typescript is not smart enough to know it
    return demandResponseParticipant.kind === 'internal'
        ? siteNumberMap[demandResponseParticipant.site_number]?.name
        : supplierNumberMap[demandResponseParticipant.supplier_number]?.name;
}

export function getParticipantNameFromSummary(
    participantSummary: SupplierDemandSummaryDTO | RecipientDemandSummaryDTO,
    siteNumberMap: SiteNumberMap,
    supplierNumberMap: SupplierNumberMap,
): string | undefined {
    const participant = 'supplier' in participantSummary ? participantSummary.supplier : participantSummary.recipient;
    return getParticipantName(participant, siteNumberMap, supplierNumberMap);
}
