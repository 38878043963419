import { Trans } from '@lingui/macro';
import { addDays, formatToIso8601Date, isPresent } from '@luminovo/commons';
import { Flexbox, Text, colorSystem } from '@luminovo/design-system';
import { DemandDateFilter } from '@luminovo/manufacturing-core';
import { Box, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { DemandSummary, useButtonGroups } from '../../Demand/DemandSummary';
import { useFilterDemandDialog } from '../../Demand/FilterDemandDialog';
import { useParticipantNumberMap } from '../../Demand/useParticipantNumberMap';
import { DemandTable } from '../../PartLibrary/IpnDetailsPage/components/DemandTab/DemandTable';

export const DemandTab = ({ assemblyIpn }: { assemblyIpn: string }): JSX.Element => {
    const buttonGroups = useButtonGroups({});

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(90, startDate));
    const setDialog = useFilterDemandDialog({
        startDate,
        endDate,
        submit: (start: Date, end: Date) => {
            setStartDate(start);
            setEndDate(end);
        },
    });

    const { data: demandData } = useHttpQuery('POST /demand/export-summary', {
        requestBody: {
            /* eslint-disable camelcase */
            ipns: [assemblyIpn],
            timeframe_type: buttonGroups.demandTimeframeType,
            participant_role: buttonGroups.participantRole,
            start_date: formatToIso8601Date(startDate),
            end_date: formatToIso8601Date(endDate),
            /* eslint-enable camelcase */
        },
    });

    const { siteNumberMap, supplierNumberMap } = useParticipantNumberMap();

    return (
        <Flexbox sx={{ background: colorSystem.neutral[1] }} flexDirection={'column'} height="100%">
            <Box alignSelf="end">
                <DemandDateFilter selectedStartDate={startDate} selectedEndDate={endDate} onEditClick={setDialog} />
            </Box>
            <DemandSummary buttonGroups={buttonGroups} demandSummary={demandData?.summary} />
            <Box height="16px" />
            <Flexbox
                sx={{
                    backgroundColor: colorSystem.neutral.white,
                    paddingY: '24px',
                    paddingX: '20px',
                    height: '100%',
                }}
                flexDirection={'column'}
                gap={'16px'}
            >
                <Text color={colorSystem.neutral[8]} variant="h3">
                    {buttonGroups.demandType === 'net' && <Trans>Net demand</Trans>}
                    {buttonGroups.demandType === 'gross' && <Trans>Gross demand</Trans>}
                </Text>
                <Box
                    sx={{
                        height: '100%',
                        minHeight: '200px',
                    }}
                >
                    {isPresent(siteNumberMap) && isPresent(supplierNumberMap) ? (
                        <DemandTable
                            demandData={demandData}
                            demandType={buttonGroups.demandType}
                            siteNumberMap={siteNumberMap}
                            supplierNumberMap={supplierNumberMap}
                        />
                    ) : (
                        <Skeleton height="200px" />
                    )}
                </Box>
            </Flexbox>
        </Flexbox>
    );
};
