import { t, Trans } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { createColumnHelper, PrimaryButton, TanStackTable, useTanStackTable } from '@luminovo/design-system';
import {
    DemandOriginExportDTO,
    DemandResponseDTO,
    DemandsSummaryResponseDTO,
    DemandTypeDTO,
} from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Upload } from '@mui/icons-material';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { NoDemandsMessage } from '../../../../../components/NoDemandsMessage';
import { route } from '../../../../../utils/routes';
import { getParticipantName } from '../../../../Demand/getParticipantName';
import { SiteNumberMap, SupplierNumberMap } from '../../../../Demand/useParticipantNumberMap';
import { SupplierOrRecipientCell } from './SupplierOrRecipientCell';

const ImportDemandsButton = () => {
    const history = useHistory();
    return (
        <PrimaryButton
            onClick={() => {
                history.push(route('/parts/demand/demand-importer'));
            }}
            size="medium"
            startIcon={<Upload />}
        >
            <Trans>Import demand</Trans>
        </PrimaryButton>
    );
};

function displayDemandOrigin(origin: DemandOriginExportDTO): string {
    switch (origin) {
        case 'imported':
            return t`Imported`;
        case 'calculated':
            return t`Calculated`;
        default:
            assertUnreachable(origin);
    }
}

function convertToDemand(
    data: DemandResponseDTO,
    demandType: DemandTypeDTO,
    siteNumberMap: SiteNumberMap,
    supplierNumberMap: SupplierNumberMap,
): Demand {
    let quantity;
    switch (demandType) {
        case 'net':
            quantity = data.net_quantity;
            break;
        case 'gross':
            quantity = data.gross_quantity;
            break;
        default:
            assertUnreachable(demandType);
    }
    return {
        startDate: data.delivery_start_date,
        endDate: data.delivery_end_date,
        supplier: getParticipantName(data.supplier, siteNumberMap, supplierNumberMap) ?? '',
        recipient: getParticipantName(data.recipient, siteNumberMap, supplierNumberMap) ?? '',
        quantity: formatQuantity(quantity),
        origin: displayDemandOrigin(data.origin),
    };
}

interface Demand {
    startDate: string;
    endDate: string;
    supplier: string;
    recipient: string;
    quantity: string;
    origin: string;
}

const columnHelper = createColumnHelper<Demand>();

const columns = [
    columnHelper.date('startDate', {
        label: () => t`Start date`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.date('endDate', {
        label: () => t`End date`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.enum('supplier', {
        label: () => t`Supplier`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.enum('recipient', {
        label: () => t`Recipient`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => <SupplierOrRecipientCell title={info.getValue()} />,
    }),
    columnHelper.number('quantity', {
        label: () => t`Quantity`,
        size: 150,
        cell: (info) => info.getValue(),
    }),
    columnHelper.enum('origin', {
        label: () => t`Origin`,
        getOptionLabel: (option) => option,
        size: 150,
        cell: (info) => info.getValue(),
    }),
];

export const DemandTable = ({
    demandData,
    demandType,
    siteNumberMap,
    supplierNumberMap,
}: {
    demandData: DemandsSummaryResponseDTO | undefined;
    demandType: DemandTypeDTO;
    siteNumberMap: SiteNumberMap;
    supplierNumberMap: SupplierNumberMap;
}): JSX.Element => {
    const data = useMemo(
        () => demandData?.demands.map((data) => convertToDemand(data, demandType, siteNumberMap, supplierNumberMap)),
        [demandData, demandType, siteNumberMap, supplierNumberMap],
    );

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnHiding: true,
        enableColumnOrdering: true,
    });

    return (
        <TanStackTable table={table} ActionButton={ImportDemandsButton} EmptyPlaceholder={() => <NoDemandsMessage />} />
    );
};
