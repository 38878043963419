import { isPresent } from '@luminovo/commons';
import { SiteDTO, SupplierDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useSelectFromSupplierAndStockLocations } from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';

export type SiteNumberMap = Record<string, SiteDTO>;
export type SupplierNumberMap = Record<string, SupplierDTO>;

export function useParticipantNumberMap(): {
    siteNumberMap: SiteNumberMap | undefined;
    isSiteNameMapLoading: boolean;
    supplierNumberMap: SupplierNumberMap | undefined;
    isSupplierNameMapLoading: boolean;
} {
    const { data: siteNumberMap, isLoading: isSiteNameMapLoading } = useHttpQuery(
        'GET /organization-settings/sites',
        {},
        {
            select: (res) =>
                res.data.sites.reduce((map, site) => {
                    if (isPresent(site.site_number)) {
                        map[site.site_number] = site;
                    }
                    return map;
                }, {} as SiteNumberMap),
        },
    );

    const { data: supplierNumberMap, isLoading: isSupplierNameMapLoading } = useSelectFromSupplierAndStockLocations({
        select: (res) =>
            res.data.reduce((map, supplier) => {
                if (isPresent(supplier.supplier_number)) {
                    map[supplier.supplier_number] = supplier.supplier;
                }
                return map;
            }, {} as SupplierNumberMap),
    });

    return { siteNumberMap, isSiteNameMapLoading, supplierNumberMap, isSupplierNameMapLoading };
}
