import { StatusChip } from '@luminovo/design-system';
import { QuoteRequest } from '../hooks/queries';
import { getQuoteRequestStatus, QuoteRequestStatus } from '../model/getQuoteRequestStatus';

export function QuoteRequestStatusChip({ quoteRequest }: { quoteRequest: QuoteRequest }) {
    const status = getQuoteRequestStatus(quoteRequest);
    return <StatusChip color={getQuoteRequestStatusColor(status)} label={status} />;
}

export function getQuoteRequestStatusColor(status: QuoteRequestStatus) {
    switch (status) {
        case 'not-sent':
            return 'neutral';
        case 'sent':
            return 'blue';
        case 'overdue':
            return 'red';
        case 'discarded':
            return 'yellow';
        case 'received':
            return 'green';
    }
}
