/* eslint-disable spellcheck/spell-checker */
import { DB } from '@luminovo/http-client';
import { Table } from '@synthql/queries';
import { Query, useQueryClient } from '@tanstack/react-query';

function predicate(tables: string[]) {
    return (query: Query) => {
        const key = query.queryKey;
        if (!key) {
            return false;
        }
        if (key[0] !== 'synthql') {
            return false;
        }
        const [, synthqlQuery] = key;
        if (!isSynthqlQuery(synthqlQuery)) {
            return false;
        }
        if (!tables.includes(synthqlQuery.from)) {
            return false;
        }
        return true;
    };
}

export function useInvalidateSynthqlTable(...tables: Table<DB>[]) {
    const queryClient = useQueryClient();
    return () => {
        return queryClient.invalidateQueries({
            predicate: predicate(tables),
        });
    };
}

export function useRemoveSynthqlQuery(...tables: Table<DB>[]) {
    const queryClient = useQueryClient();
    return () => {
        return queryClient.removeQueries({ predicate: predicate(tables) });
    };
}

function isSynthqlQuery(query: unknown): query is { from: string } {
    return typeof query === 'object' && query !== null && 'from' in query;
}
