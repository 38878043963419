/* eslint-disable camelcase */
import { groupBy, isPresent, omit, pick, sortBy, uniqBy } from '@luminovo/commons';
import {
    ApprovalStatus,
    AssemblyDTO,
    AvailabilityDTO,
    BomItemApprovalStatus,
    BomItemDTO,
    BomItemGeneralProperties,
    BomItemIssue,
    BomItemPartDataIssues,
    ColumnMap,
    ComplianceStatus,
    CustomPartSpecification,
    DesignItemExcelOrigin,
    DesignItemOriginDTO,
    DesignItemOriginTypes,
    DesignItemResponseDTO,
    EmissionData,
    FullPart,
    LifecycleEnum,
    PartCountEnum,
    PartSpecification,
    RfqContext,
    StandardPartDTO,
} from '@luminovo/http-client';
import * as React from 'react';
import { useDescendants } from '../../../../resources/assembly/assemblyHandler';
import { BomItem } from '../../../../resources/designItem/bomItemFrontendTypes';
import { PartSpecificationTypes } from '../../../../resources/part/PartSpecificationTypes';
import { FullPartWithApprovalStatus } from '../../../../resources/part/partFrontendTypes';
import {
    getFullPartsWithApprovalStatus,
    useCustomComponentsBulk,
    useCustomPartsBulk,
    useFullStandardPartsBulk,
} from '../../../../resources/part/partHandler';
import { getPartIds, getPartIdsSet } from '../../../../utils/getPartIds';
import { getUniqueCustomSpecificationIds, getUniqueStandardParts } from '../../../../utils/getUniqueStandardParts';
import { stringifyWithSortedKeys } from '../../../../utils/stringify';
import { assertUnreachable, typed } from '../../../../utils/typingUtils';

type FullPartsFromDesignItems = {
    fullPartsForPartOptions: FullPartWithApprovalStatus[];
    isLoading: boolean;
    isFetched: boolean;
};

export interface BomItemWithIndividualDesignItems {
    designItem: DesignItemResponseDTO;
    individualDesignItems: DesignItemResponseDTO[];
}

interface BomItemWithLifecycleComplianceAndAvailability extends BomItemWithIndividualDesignItems {
    reach_compliant?: ComplianceStatus;
    rohs_compliant?: ComplianceStatus;
    aecq_compliant?: ComplianceStatus;
    lifecycle_status?: LifecycleEnum;
    availability: AvailabilityDTO | null;
    yearsToEndOfLife: number | null;
    partOptionCardinality: PartCountEnum;
    approvalStatus: BomItemApprovalStatus;
    issues: BomItemIssue[];
    part_data_issues: BomItemPartDataIssues[];
    general_properties: BomItemGeneralProperties[];
    emission_data: EmissionData | null;
}

const getidsOfIndividualDesignItemsInBomItem = (bomItem: BomItemWithIndividualDesignItems): string[] => {
    return bomItem.individualDesignItems.map((d) => d.id).sort(); // sort so that ids order is consistent (this is useful for example for caching part alternatives fetches)
};

const getDesignatorsOfBomItem = (bomItem: BomItemWithIndividualDesignItems): string[] => {
    const result: string[] = [];
    for (const designItem of bomItem.individualDesignItems) {
        if (designItem.designator !== null) {
            result.push(designItem.designator);
        }
    }
    return result;
};

export const stringifyPartSpecification = (partSpecification: PartSpecification | null): string => {
    if (partSpecification === null) {
        return 'null';
    }
    if (partSpecification.type === PartSpecificationTypes.Custom) {
        return stringifyWithSortedKeys(typed<CustomPartSpecification>(partSpecification));
    }
    if (partSpecification.type === PartSpecificationTypes.OffTheShelf) {
        return stringifyWithSortedKeys({
            ...partSpecification,
            data: {
                ...partSpecification.data,
                part_options: [...partSpecification.data.part_options]
                    .map(stringifyWithSortedKeys)
                    .sort((a, b) => a.localeCompare(b)),
            },
        });
    }
    assertUnreachable(partSpecification);
};

function aggregationKey(designItem: DesignItemResponseDTO, columnMap: ColumnMap | null): string {
    return stringifyWithSortedKeys([
        designItem.notes,
        designItem.assembly,
        designItem.do_not_place,
        designItem.quantity?.unit,
        designItem.quantity?.quantity,
        designItem.is_consigned,
        stringifyPartSpecification(designItem.part_specification),
        stringifyOriginWithoutDesignator(designItem.origin, designItem.id, columnMap),
    ]);
}

function stringifyOriginWithoutDesignator(
    origin: DesignItemOriginDTO,
    designItemId: string,
    columnMap: ColumnMap | null,
): string {
    if (!columnMap) {
        return 'noColumnMap'; // Don't differentiate by origin if columnMap is not available
    }
    if (origin.type === DesignItemOriginTypes.Manual) {
        return DesignItemOriginTypes.Manual.toString();
    }
    if (origin.type === DesignItemOriginTypes.ExcelFile) {
        return stringifyWithSortedKeys(excelOriginWithoutDesignators(columnMap, origin.data));
    }
    if (origin.type === DesignItemOriginTypes.PCB) {
        return designItemId; // Do not aggregate PCBs
    }
    if (origin.type === DesignItemOriginTypes.AssemblyImport) {
        return DesignItemOriginTypes.AssemblyImport.toString();
    }
    assertUnreachable(origin);
}

const excelOriginWithoutDesignators = (columnMap: ColumnMap, excelOrigin?: DesignItemExcelOrigin) => {
    if (!excelOrigin) return true;
    const columnMapWithoutDesignators = omit(columnMap, ['designators']);
    const columnIndexesAsStrings = Object.values(columnMapWithoutDesignators).flatMap((column_indexes) =>
        column_indexes.map((index) => String(index)),
    );
    const COMMON_LINE_NUMBER_THAT_GUARANTEES_NO_COLLISION_BETWEEN_DESIGN_ITEMS = -1;
    return {
        ...excelOrigin,
        excel_lines: {
            line_number: COMMON_LINE_NUMBER_THAT_GUARANTEES_NO_COLLISION_BETWEEN_DESIGN_ITEMS,
            raw_original_line: pick(excelOrigin.excel_lines[0].raw_original_line, columnIndexesAsStrings),
        },
        excel_lines_id: null, // TODO remove as part of: https://www.notion.so/luminovo/Create-new-DesignItems-with-ExcelFileV2-origin-4e4beb711f6b45c7bcdb61675cc1e310
    };
};

// Cannot be removed as of now, since this function is being used in `sourcingFunctions.ts`
export function getBomItemsWithIndividualDesignItems(
    designItems: DesignItemResponseDTO[],
    columnMap: ColumnMap | null = null,
): BomItemWithIndividualDesignItems[] {
    const grouped = groupBy(designItems, (c) => aggregationKey(c, columnMap));
    return Object.values(grouped).map((groupOfDesignItems) => ({
        designItem: groupOfDesignItems[0],
        individualDesignItems: groupOfDesignItems,
    }));
}

export const useAssemblyDesignItems = (assemblyId: string) => {
    const { data: descendantsData, isFetched } = useDescendants(assemblyId);
    const designItems: DesignItemResponseDTO[] = React.useMemo(() => {
        return sortBy(
            (descendantsData?.data.design_items ?? []).filter((designItem) => {
                return designItem.assembly === assemblyId;
            }),
            (c) => c.notes,
        );
    }, [descendantsData, assemblyId]);

    return { descendantsData, designItems, isFetched };
};

type MapOfDesignItemIdToDesignItem = Record<string, DesignItemResponseDTO>;

const defaultDesignItems: DesignItemResponseDTO[] = [];

type UseAggregateDesignItemsToBomItemsProps = {
    rfqId: string | undefined;
    designItems?: DesignItemResponseDTO[];
    assemblies?: AssemblyDTO[];
    showPartAlternatives: boolean;
    currentParentId: string;
};

export function useAggregateDesignItemsToBomItems({
    rfqId,
    designItems,
    assemblies,
    showPartAlternatives,
    currentParentId,
}: UseAggregateDesignItemsToBomItemsProps) {
    const parentDesignItems = React.useMemo(() => {
        const allDesignItems = designItems ?? defaultDesignItems;
        return allDesignItems.filter((designItem) => designItem.assembly === currentParentId);
    }, [currentParentId, designItems]);

    const mappingOfDesignItemIdToDesignItems = React.useMemo(() => {
        const result: MapOfDesignItemIdToDesignItem = {};

        for (const designItem of parentDesignItems) {
            result[designItem.id] = designItem;
        }

        return result;
    }, [parentDesignItems]);

    const {
        fullPartsForPartOptions,
        isLoading: areFullPartsLoading,
        isFetched: isFullPartsFetched,
    } = useFullPartsFromDesignItems(rfqId, parentDesignItems);

    const bomItems = React.useMemo(() => {
        const parentBomItems = assemblies?.find((assembly) => assembly.id === currentParentId)?.bom_items ?? [];
        return createBomItems({
            designItems: mappingOfDesignItemIdToDesignItems,
            fullPartsFromPartOptionsWithApprovalStatus: fullPartsForPartOptions,
            bomItems: parentBomItems,
            showPartAlternatives,
        });
    }, [
        assemblies,
        currentParentId,
        fullPartsForPartOptions,
        mappingOfDesignItemIdToDesignItems,
        showPartAlternatives,
    ]);

    const isLoading = areFullPartsLoading;

    const isFetched = isFullPartsFetched;
    return { bomItems, isLoading, isFetched };
}

function mapBOMItemsDTOToBomItemWithIndividualDesignItems(
    bomItems: BomItemDTO[],
    designItems: Record<string, DesignItemResponseDTO>,
): BomItemWithLifecycleComplianceAndAvailability[] {
    const bomItemResult: BomItemWithLifecycleComplianceAndAvailability[] = [];
    for (const bomItem of bomItems) {
        const individualDesignItems = resolveDefinedDesignItems(bomItem.design_items, designItems);
        // just as a pre-caution, only add the bomItem if it has 1 or more designItem.
        if (individualDesignItems.length !== 0) {
            bomItemResult.push({
                designItem: individualDesignItems[0],
                individualDesignItems,
                reach_compliant: bomItem.reach_compliant,
                rohs_compliant: bomItem.rohs_compliant,
                aecq_compliant: bomItem.aecq_compliant,
                lifecycle_status: bomItem.lifecycle_status,
                availability: bomItem.availability,
                yearsToEndOfLife: bomItem.years_to_end_of_life,
                partOptionCardinality: bomItem.part_option_cardinality,
                issues: bomItem.issues,
                approvalStatus: bomItem.approval_status,
                part_data_issues: bomItem.part_data_issues,
                general_properties: bomItem.general_properties,
                emission_data: bomItem.emission_data,
            });
        }
    }
    return bomItemResult;
}

// ensure that the designItems are defined
function resolveDefinedDesignItems(
    designItems: string[],
    designItemsMap: Record<string, DesignItemResponseDTO>,
): DesignItemResponseDTO[] {
    const result = [];
    for (const designItem of designItems) {
        const fullDesignItemDTO: DesignItemResponseDTO | undefined = designItemsMap[designItem];
        if (fullDesignItemDTO) result.push(fullDesignItemDTO);
    }
    return result;
}

/**
 * Aggregates design items and adds full part information
 *
 * Note: A BomItem is NOT a back-end construct
 */
function createBomItems({
    designItems,
    fullPartsFromPartOptionsWithApprovalStatus,
    bomItems: bomItemsDTO,
    showPartAlternatives,
}: {
    fullPartsFromPartOptionsWithApprovalStatus: FullPartWithApprovalStatus[];
    bomItems: BomItemDTO[];
    designItems: Record<string, DesignItemResponseDTO>;
    showPartAlternatives: boolean;
}): BomItem[] {
    const bomItems = mapBOMItemsDTOToBomItemWithIndividualDesignItems(bomItemsDTO, designItems);
    const fullPartOptionsMap: Map<string, FullPartWithApprovalStatus> = new Map(
        fullPartsFromPartOptionsWithApprovalStatus.map((partOption) => [partOption.part.id, partOption]),
    );
    return bomItems.map((bomItem) => {
        const bomFileIds = new Set(
            bomItem.individualDesignItems
                .map((designItem) =>
                    designItem.origin.type === DesignItemOriginTypes.ExcelFile
                        ? (designItem.origin.data?.bom_file_id ?? '')
                        : '',
                )
                .filter((fileId) => fileId.length > 0),
        );

        const designItemPartIDs = getPartIdsSet(bomItem.designItem.part_specification, { filterByStatus: false });
        const parts: FullPartWithApprovalStatus[] = Array.from(designItemPartIDs)
            .map((partID) => fullPartOptionsMap.get(partID))
            .filter(isPresent);

        const uniqueBomItemParts = uniqBy(parts, (data) => data.part.id /* the part id */);

        const approvedPartOptions: FullPart[] = uniqueBomItemParts.reduce((result: FullPart[], part) => {
            if (part.approvalStatus === ApprovalStatus.Approved) {
                result.push(part.part);
            }
            return result;
        }, []);

        const ids = getidsOfIndividualDesignItemsInBomItem(bomItem);

        const pcb: string | undefined =
            bomItem.designItem.origin.type === DesignItemOriginTypes.PCB &&
            typeof bomItem.designItem.origin.data === 'string'
                ? bomItem.designItem.origin.data
                : undefined;

        const excel_lines =
            bomItem.designItem.origin.type === DesignItemOriginTypes.ExcelFile
                ? bomItem.designItem.origin.data?.excel_lines
                : undefined;

        const returned: BomItem = {
            id: ids,
            designator: getDesignatorsOfBomItem(bomItem),
            quantity: bomItem.designItem.quantity,
            doNotPlace: bomItem.designItem.do_not_place,
            ignorePackageNameMismatch: bomItem.designItem.ignore_package_name_mismatch,
            notes: bomItem.designItem.notes,
            parentId: bomItem.designItem.assembly,
            optionIds: getPartIds(bomItem.designItem.part_specification, { filterByStatus: false }),
            specification: bomItem.designItem.part_specification,
            parts: uniqueBomItemParts,
            approvedPartOptions: approvedPartOptions,
            origin: {
                type: bomItem.designItem.origin.type,
                bomFileIds: Array.from(bomFileIds),
                originalRowNumber: excel_lines ? excel_lines[0]?.line_number : undefined,
                originalRowNumbers: excel_lines?.map((l) => l.line_number) ?? [],
                pcb: pcb,
            },
            individualDesignItems: bomItem.individualDesignItems,
            isConsigned: bomItem.designItem.is_consigned,
            availability: bomItem.availability,
            reachCompliant: bomItem.reach_compliant,
            rohsCompliant: bomItem.rohs_compliant,
            aecqCompliant: bomItem.aecq_compliant,
            lifecycleStatus: bomItem.lifecycle_status,
            showPartAlternatives,
            emissionData: bomItem.emission_data,
            yearsToEndOfLife: bomItem.yearsToEndOfLife,
            approvedPartOptionCardinality: bomItem.partOptionCardinality,
            approvalStatus: bomItem.approvalStatus,
            issues: bomItem.issues,
            partDataIssues: bomItem.part_data_issues,
            generalProperties: bomItem.general_properties,
        };
        return returned;
    });
}

function useFullPartsFromDesignItems(
    rfqId: string | undefined,
    designItems: DesignItemResponseDTO[],
): FullPartsFromDesignItems {
    const allStandardParts: StandardPartDTO[] = React.useMemo(() => {
        return getUniqueStandardParts(designItems);
    }, [designItems]);

    const {
        fullParts,
        isLoading: isLoadingOtsParts,
        isFetched: isFullPartsFetched,
    } = useFullStandardPartsBulk(allStandardParts, rfqId);

    const { customPartIds, customComponentIds } = React.useMemo(() => {
        return getUniqueCustomSpecificationIds(designItems);
    }, [designItems]);

    const {
        data: customParts = [],
        isLoading: isLoadingCustomParts,
        isFetched: isCustomPartsFetched,
    } = useCustomPartsBulk(customPartIds);
    const customFullParts: FullPart[] = customParts;

    const rfqContext: RfqContext = React.useMemo(() => {
        if (rfqId)
            return {
                type: 'WithinRfQ',
                rfq_id: rfqId,
            };
        return {
            type: 'OutsideRfQ',
        };
    }, [rfqId]);

    const { data: customComponents = [], isLoading: isLoadingComponents } = useCustomComponentsBulk(
        customComponentIds,
        rfqContext,
    );

    const allFullParts = React.useMemo(() => {
        return [...customFullParts, ...fullParts, ...customComponents];
    }, [customFullParts, fullParts, customComponents]);

    const fullPartsForPartOptions = React.useMemo(() => {
        return designItems.flatMap((designItem) =>
            getFullPartsWithApprovalStatus(
                designItem.part_specification,
                new Map(allFullParts.map((part) => [part.id, part])),
            ),
        );
    }, [allFullParts, designItems]);

    const isLoading = isLoadingOtsParts || isLoadingCustomParts || isLoadingComponents;
    const isFetched = isFullPartsFetched || isCustomPartsFetched;
    return {
        fullPartsForPartOptions,
        isLoading,
        isFetched,
    };
}
