/* eslint-disable camelcase */
import { UseQueryResult } from '@tanstack/react-query';
import { useSynth } from '../../../components/contexts/SynthQLContext';
import { QuoteRequestLineItem, quoteRequestLineItem } from './queries';

export function useQuoteRequestLineItems({
    negotiationLineItemId,
    quoteRequestId,
}: {
    negotiationLineItemId?: number;
    quoteRequestId?: number;
}): UseQueryResult<QuoteRequestLineItem[]> {
    const query = quoteRequestLineItem()
        .where({ negotiation_line_item_id: negotiationLineItemId, quote_request_id: quoteRequestId })
        .all();

    return useSynth({ query, enabled: Boolean(negotiationLineItemId) || Boolean(quoteRequestId) });
}
