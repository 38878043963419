import { CenteredLayout, Flexbox, Text, Toolbar, colorSystem } from '@luminovo/design-system';
import { Box, BoxProps, CircularProgress } from '@mui/material';
import * as React from 'react';
import { route } from '../../../utils/routes';
import { useNegotiation } from '../hooks/useNegotiation';
import { useNegotiationLineItems } from '../hooks/useNegotiationLineItems';
import { useQuoteRequest } from '../hooks/useQuoteRequest';
import { formatNegotiationPart } from '../model/formatNegotiationPart';
import { formatQuoteRequest } from '../model/formatQuoteRequest';
import { getNegotiationLineItemParts } from '../model/getNegotiationLineItemParts';
export function NegotiationsLayout({
    children,
    negotiationLineItemId,
    negotiationId,
    quoteRequestId,
    title,
    status,
    actions,
    ...props
}: {
    children: React.ReactNode;
    negotiationId?: number;
    negotiationLineItemId?: number;
    quoteRequestId?: number;
    title?: string;
    actions?: React.ReactNode;
    status?: React.ReactNode;
} & BoxProps) {
    const sidebarWidth = 50;
    return (
        <Box
            sx={{
                background: colorSystem.neutral[1],
                height: '100vh',
                maxHeight: '100vh',
                overflow: 'auto',
                paddingLeft: `${sidebarWidth}px`,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    zIndex: 1000,
                    background: colorSystem.neutral[8],
                    width: sidebarWidth,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '12px',
                    color: colorSystem.neutral[1],
                }}
            ></Box>
            <React.Suspense
                fallback={
                    <Toolbar
                        style={{
                            background: colorSystem.neutral[1],
                            borderBottom: `0px solid ${colorSystem.neutral[1]}`,
                        }}
                        breadcrumbs={[
                            {
                                title: 'Negotiations',
                                href: route('/negotiations'),
                            },
                            {
                                title: '...',
                            },
                        ]}
                    />
                }
            >
                <NegotiationsLayoutToolbar
                    negotiationId={negotiationId}
                    negotiationLineItemId={negotiationLineItemId}
                    quoteRequestId={quoteRequestId}
                />
            </React.Suspense>
            <Box
                {...props}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2.5,
                    paddingTop: '0',
                    height: '100%',
                    gap: 2,
                    boxSizing: 'border-box',
                    ...props.sx,
                }}
            >
                <Flexbox alignItems="center" gap="8px">
                    {title && <Text variant="h1">{title}</Text>}
                    {status}
                    <div style={{ flex: 1 }} />
                    {actions}
                </Flexbox>
                <React.Suspense
                    fallback={
                        <CenteredLayout>
                            <CircularProgress />
                        </CenteredLayout>
                    }
                >
                    {children}
                </React.Suspense>
            </Box>
        </Box>
    );
}

function NegotiationsLayoutToolbar({
    negotiationId,
    negotiationLineItemId,
    quoteRequestId,
    actions,
}: {
    negotiationId?: number;
    negotiationLineItemId?: number;
    quoteRequestId?: number;
    actions?: React.ReactNode;
}) {
    const breadcrumbNegotiation = useBreadcrumbsNegotiation(negotiationId);
    const breadcrumbNegotiationLineItem = useBreadcrumbsNegotiationLineItem(negotiationId, negotiationLineItemId);
    const breadcrumbQuoteRequest = useBreadcrumbsQuoteRequest(negotiationId, quoteRequestId);
    return (
        <Toolbar
            style={{
                background: colorSystem.neutral[1],
                borderBottom: `0px solid ${colorSystem.neutral[1]}`,
                // position: 'sticky',
                // top: 0,
                // zIndex: 1000,
            }}
            breadcrumbs={[
                {
                    title: 'Negotiations',
                    href: route('/negotiations'),
                },
                ...breadcrumbNegotiation,
                ...breadcrumbNegotiationLineItem,
                ...breadcrumbQuoteRequest,
            ]}
        >
            {actions}
        </Toolbar>
    );
}

function useBreadcrumbsNegotiation(negotiationId?: number) {
    const { data: negotiation } = useNegotiation(negotiationId);

    if (negotiationId === undefined) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId', { negotiationId }),
            title: negotiation?.name ?? '',
        },
    ];
}

function useBreadcrumbsNegotiationLineItem(negotiationId?: number, negotiationLineItemId?: number) {
    const { data: negotiationLineItem } = useNegotiationLineItems(negotiationId);
    const lineItem = negotiationLineItem?.find((item) => item.id === negotiationLineItemId);

    if (negotiationId === undefined || !lineItem) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId', { negotiationId }),
            title: getNegotiationLineItemParts(lineItem).map(formatNegotiationPart).join(', '),
        },
    ];
}

function useBreadcrumbsQuoteRequest(negotiationId?: number, quoteRequestId?: number) {
    const { data: quoteRequest } = useQuoteRequest({ quoteRequestId });

    if (quoteRequestId === undefined || !quoteRequest || !negotiationId) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId/quote-requests/:quoteRequestId', {
                negotiationId,
                quoteRequestId,
            }),
            title: formatQuoteRequest(quoteRequest),
        },
    ];
}
