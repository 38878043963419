import { SecondaryButton } from '@luminovo/design-system';
import { Archive } from '@mui/icons-material';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useInvalidateSynthqlTable } from '../hooks/useInvalidateSynthqlTable';
import { useQuoteRequest } from '../hooks/useQuoteRequest';
import { getQuoteRequestStatus } from '../model/getQuoteRequestStatus';

export function ButtonDiscardQuoteRequest({ quoteRequestId }: { quoteRequestId: number }) {
    const invalidate = useInvalidateSynthqlTable('quote_request', 'quote_request_line_item');
    const { mutateAsync, isLoading } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: 'Quote request updated',
        onSuccess: async () => {
            invalidate();
        },
    });

    const { data: quoteRequest } = useQuoteRequest({ quoteRequestId });

    if (!quoteRequest) {
        return null;
    }

    const status = getQuoteRequestStatus(quoteRequest);

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Archive />}
            isLoading={isLoading}
            onClick={() =>
                mutateAsync({
                    pathParams: { id: quoteRequestId },
                    requestBody: { discarded: status !== 'discarded' },
                })
            }
        >
            {status === 'discarded' ? 'Undo discard' : 'Discard'}
        </SecondaryButton>
    );
}
