import { formatDecimal, formatRelativeTime, formatToLongDate } from '@luminovo/commons';
import {
    FieldCheckbox,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldNumeric,
    FieldTextControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { Refresh } from '@mui/icons-material';
import { Box, InputAdornment } from '@mui/material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LayoutCard } from '../../../components/LayoutCard';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { UrlParams } from '../../../utils/routes/routes';
import { ButtonDiscardQuoteRequest } from '../components/ButtonDiscardQuoteRequest';
import { ButtonDownloadQuoteRequestExcel } from '../components/ButtonDownloadQuoteRequestExcel';
import { ButtonSendQuoteRequests } from '../components/ButtonSendQuoteRequests';
import { FieldSelectAwardScenario, useAwardScenarioQueryParam } from '../components/FieldSelectAwardScenario';
import { NegotiationsLayout } from '../components/NegotiationsLayout';
import { PropertiesTable } from '../components/PropertiesTable';
import { QuoteRequestStatusChip } from '../components/QuoteRequestStatusChip';
import { ColumnId, TableQuoteRequestLineItems } from '../components/TableQuoteRequestLineItems';
import { AwardScenario, QuoteRequest } from '../hooks/queries';
import { useAwardScenarios } from '../hooks/useAwardScenarios';
import { useQuoteRequest } from '../hooks/useQuoteRequest';
import { useQuoteRequestLineItems } from '../hooks/useQuoteRequestLineItems';
import { formatQuoteRequest } from '../model/formatQuoteRequest';
import { getQuoteRequestStatus } from '../model/getQuoteRequestStatus';

type QuoteRequestForm = {
    notes: string;
    showCustomerName: boolean;
    showTargetPrice: boolean;
    referenceScenarioId: number | null;
    quoteRequest: QuoteRequest;
    targetSavings:
        | {
              type: 'random';
              from: number;
              to: number;
          }
        | { type: 'fixed'; value: number };
};

export function QuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = Number(pathParams.quoteRequestId);
    const { data: quoteRequest } = useQuoteRequest({ quoteRequestId });
    const { data: scenarios } = useAwardScenarios(negotiationId);
    const [referenceScenario, setReferenceScenario] = useAwardScenarioQueryParam(negotiationId, 'referenceScenarioId');

    const { data: lineItems = [] } = useQuoteRequestLineItems({ quoteRequestId });
    if (!quoteRequest) {
        return <></>;
    }

    const defaultValues: QuoteRequestForm = {
        notes: '',
        showCustomerName: false,
        showTargetPrice: false,
        referenceScenarioId: scenarios?.[0]?.id ?? null,
        targetSavings: { type: 'fixed', value: 5 },
        quoteRequest,
    };

    return (
        <NegotiationsLayout
            negotiationId={negotiationId}
            quoteRequestId={quoteRequestId}
            title={formatQuoteRequest(quoteRequest)}
            status={<QuoteRequestStatusChip quoteRequest={quoteRequest} />}
            actions={
                <>
                    <ButtonDiscardQuoteRequest quoteRequestId={quoteRequestId} />
                    <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
                    <ButtonSendQuoteRequests quoteRequests={[quoteRequest]} />
                </>
            }
        >
            <FormContainer defaultValues={defaultValues} onSubmit={() => {}}>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'minmax(15vw, auto) 1fr', gap: 2, height: '100%' }}>
                    <Flexbox flexDirection="column" gap="16px">
                        <LayoutCard>
                            <PropertiesTable
                                properties={{
                                    [`Line items`]: formatDecimal(quoteRequest.lineItems.length),
                                    [`Reference volume`]: '0',
                                    [`Target volume`]: '0',
                                    [`Quoted volume`]: '0',
                                }}
                            />
                        </LayoutCard>

                        <LayoutCard>
                            <FormItemDueDate />

                            <FormItemNotes />

                            <FormItemOptionalColumns />

                            <FormItemReferenceScenario
                                negotiationId={negotiationId}
                                referenceScenario={referenceScenario}
                                setReferenceScenario={setReferenceScenario}
                            />

                            <FormItemExtraTargetSavings />
                        </LayoutCard>
                    </Flexbox>

                    <LayoutCard style={{ flexGrow: 1 }}>
                        <TableQuoteRequestLineItems
                            lineItems={lineItems}
                            negotiationId={negotiationId}
                            enabledColumns={[
                                // TODO(negotiations) add support for these columns
                                // ColumnId.BomItem,
                                ColumnId.IPN,
                                ColumnId.RequestedPart,
                                ColumnId.RequiredQuantity,
                                ColumnId.PotentialQuantity,
                                ColumnId.TargetPrice,
                                ColumnId.OfferedPart,
                                ColumnId.Status,
                            ]}
                        />
                    </LayoutCard>
                </Box>
            </FormContainer>
        </NegotiationsLayout>
    );
}

function useIsReadonly() {
    const { control } = useFormContext<QuoteRequestForm>();
    const quoteRequest = useWatch({ control, name: 'quoteRequest' });

    const status = getQuoteRequestStatus(quoteRequest);
    return status !== 'not-sent';
}

function FormItemContainer({ label, read, edit }: { label: string; read: React.ReactNode; edit: React.ReactNode }) {
    const content = useIsReadonly() ? read : edit;
    if (!content) {
        return null;
    }
    return <FormItem label={label}>{content}</FormItem>;
}

function FormItemNotes() {
    const { control } = useFormContext<QuoteRequestForm>();
    const notes = useWatch({ control, name: 'notes' });

    return (
        <FormItemContainer
            label="Notes"
            read={<Text>{notes || `No notes`}</Text>}
            edit={
                <FieldTextControlled
                    control={control}
                    name="notes"
                    FieldProps={{
                        placeholder: 'Start typing...',
                        multiline: true,
                        minRows: 2,
                    }}
                />
            }
        />
    );
}

function FormItemDueDate() {
    const { control } = useFormContext<QuoteRequestForm>();
    const dueDate = useWatch({ control, name: 'quoteRequest.due_date' });
    const relative = dueDate && formatRelativeTime(dueDate);

    return (
        <FormItemContainer
            label="Due date"
            read={<Text>{dueDate ? formatToLongDate(dueDate) : 'None'}</Text>}
            edit={
                <>
                    <FieldDateControlled
                        control={control}
                        name="quoteRequest.due_date"
                        FieldProps={{
                            placeholder: 'Select due date...',
                        }}
                    />
                    <Text>{relative}</Text>
                </>
            }
        />
    );
}

function FormItemOptionalColumns() {
    const { control } = useFormContext<QuoteRequestForm>();

    return (
        <FormItemContainer
            label="Optional columns"
            read={undefined}
            edit={
                <>
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled control={control} name="showCustomerName" />
                        <Text>Customer name</Text>
                    </Flexbox>
                    <Flexbox flexDirection="row" gap="8px" alignItems="center">
                        <FieldCheckboxControlled control={control} name="showTargetPrice" />
                        <Text>Target price</Text>
                    </Flexbox>
                </>
            }
        />
    );
}

function FormItemReferenceScenario({
    negotiationId,
    referenceScenario,
    setReferenceScenario,
}: {
    negotiationId: number;
    referenceScenario: AwardScenario | null;
    setReferenceScenario: (value: AwardScenario | null) => void;
}) {
    return (
        <FormItemContainer
            label="What should the target price refer to?"
            read={undefined}
            edit={
                <FieldSelectAwardScenario
                    negotiationId={negotiationId}
                    value={referenceScenario}
                    onChange={(newScenario) => setReferenceScenario(newScenario)}
                />
            }
        />
    );
}

function FormItemExtraTargetSavings() {
    const { control } = useFormContext<QuoteRequestForm>();

    function isPercentageInRange(value: number) {
        return value >= 0 && value <= 100;
    }

    const edit = (
        <Controller
            control={control}
            name="targetSavings"
            rules={{
                validate: (value) => {
                    const values = value.type === 'fixed' ? [value.value] : [value.from, value.to];
                    if (values.some((value) => !isPercentageInRange(value))) {
                        return 'Invalid range';
                    }
                    if (value.type === 'random' && value.to <= value.from) {
                        return 'Invalid range';
                    }
                },
            }}
            render={({ field, formState }) => {
                const error = formState.errors.targetSavings?.message;
                const type = field.value.type;
                return (
                    <>
                        <FieldNumeric
                            error={type === 'fixed' && Boolean(error)}
                            InputProps={{
                                disabled: type !== 'fixed',
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            value={field.value.type === 'fixed' ? field.value.value : null}
                            onChange={(value) => {
                                field.onChange({ type: 'fixed', value });
                            }}
                            onBlur={field.onBlur}
                        />

                        <Flexbox gap="8px">
                            <FieldCheckbox
                                value={type === 'random'}
                                onChange={(value) => {
                                    field.onChange(
                                        value
                                            ? { type: 'random', from: 3, to: 7 }
                                            : {
                                                  type: 'fixed',
                                                  value: 5,
                                              },
                                    );
                                    field.onBlur();
                                }}
                                onBlur={field.onBlur}
                            />
                            <Text>Use random percentage</Text>
                        </Flexbox>

                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '90px 90px 1fr',
                                gap: 1,
                                height: '100%',
                            }}
                        >
                            <Text variant="h4">From</Text>
                            <Text variant="h4">To</Text>
                            <Text></Text>
                            <FieldNumeric
                                error={type === 'random' && Boolean(error)}
                                InputProps={{
                                    disabled: type !== 'random',
                                    placeholder: '',
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onBlur={field.onBlur}
                                value={field.value.type === 'random' ? field.value.from : null}
                                onChange={(value) => {
                                    if (type === 'random') {
                                        field.onChange({ type: 'random', from: value, to: field.value.to });
                                    }
                                }}
                            />

                            <FieldNumeric
                                error={type === 'random' && Boolean(error)}
                                sx={{ maxWidth: 90 }}
                                InputProps={{
                                    disabled: type !== 'random',
                                    placeholder: '',
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onBlur={field.onBlur}
                                value={field.value.type === 'random' ? field.value.to : null}
                                onChange={(value) => {
                                    if (type === 'random') {
                                        field.onChange({ type: 'random', from: field.value.from, to: value });
                                    }
                                }}
                            />

                            <SecondaryButton startIcon={<Refresh />} disabled={type !== 'random'}>
                                Randomize
                            </SecondaryButton>
                        </Box>
                    </>
                );
            }}
        />
    );
    return <FormItemContainer label="Extra target savings" read={undefined} edit={edit} />;
}
