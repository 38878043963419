/* eslint-disable camelcase */
import { NegotiationPart, QuoteRequestLineItem } from '../hooks/queries';

export function getQuoteRequestLineItemRequestedPart(row: QuoteRequestLineItem): NegotiationPart | undefined {
    if (!row.requestedOtsPart) {
        return undefined;
    }
    if (row.ipn_id) {
        // TODO(negotiations) add proper support for these fields, or remove them from NegotiationPart.
        return {
            kind: 'component',
            ipn_id: row.ipn_id,
            generic_part_matches: [],
            off_the_shelf_part_matches: [],
            raw_specifications: {},
        };
    }
    return { kind: 'off_the_shelf' as const, ...row.requestedOtsPart };
}
